//header
/*Unwanted important rules removed by AK*/
@import "variables";

// Oxide register, change display to block to see it in action
#register, #signIn { 
  display: none;
}
#register + .nav-link {
  display: none;
}

.skip-to-content-link {
  position: fixed;
  left: 50%;  
  transform: translateY(-100%);
  background: #eee;  ;
  left: 50%;
  padding: 16px 12px 12px; 
  display: flex;
  font-size: 14px;
  font-weight: 600;
  color: $dark-blue;
  transform: translateY(-100%);
  transition: transform 0.3s;
  z-index: 105; // more than dropdowns and nav-bg
}
.skip-to-content-link:focus {
  transform: translateY(-4px);
}
.skip-to-content-link:hover {  
  background: #eee;
}
.logged-in .skip-to-content-link {
  // no need
}

#header-inner {
  & {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-height: 41px ;
    max-width: 1350px;
    width: 100%;
    margin: auto;
  }
  .navbar {
    visibility: hidden;
    opacity: 0;
    //We'll load the whole nav with jquery after vueJs has added the Register+Sign-in
    transition: opacity 0.3s ease;
  }
  .dh-tag {
    white-space: nowrap;
    margin-right: auto;
    font-size: 14px;
    color: #fff;
  }
  span.dh-tag a,  a.nav-link {
    color: #fff;
    font-weight: 500;
  }
  span a {
    text-decoration: underline;
  }
  li > a {
    // text-decoration: underline;
    &:hover { 
      // text-decoration: none;
    }
  }
  .menu {
    display: flex;
    justify-content: space-around;
    text-decoration: none;
    height: 100%;
    padding: 0 !important;
    margin: 0 !important;
    a {
      font-size: 14px;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    .sign-in, .register, .content-requests, .news-and-events {
      @media (max-width: 1024px) {
        display: none;
      }
    }
    .sign-in {
      position: relative;
      &::before {
        content: '/' !important;
        position: absolute;
        left: -20px;
      }
    }
    // commneted out on 19 july 2022, because affects register pop up
    // li {
    //   align-self: center;
    //   display: flex;
    //   align-items: center;
    //   height: 100%;
    // }
    .current-menu-item {
      background-color: white;
      border-radius: 10px 10px 0 0;
      padding: 0 12px;
      a{
        color: $blue !important;
        border-bottom: none;
      }
    }
  } 
}


#search-inner {
  & {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: none;
  }
  .navbar {
    & {
      width: 100%;
      position: static;
    }
    .container {
      max-width: none;
      padding: 0px 20px
    }
  }
  .navbar-brand img {    
    max-width: 175px;
    overflow: hidden; //for slow networks; alt text will be hidden
    height: auto; 
  }

  .search-menu {
    //width: 100%;
    a {
      font-size: 14px;
      font-weight: 500;
      text-decoration: none !important;
      color: $blue;
    }
    li{
      padding: 0;
      &:hover {
        // border-radius: 3px;
        // border-bottom: 3px solid #25abe0;
        // margin-bottom: -3px;
      }
    }

    @media (max-width: 768px) {
      //display: none;
    }
    @media (max-width: 1440px) {
      li{
        //width: 140px;
        flex-flow: wrap;
      }
    }
  }
}


#search-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1320px; /* 1320 is the exact length of box shadow in search menu */
  width: 100%;  
  background-color: white;
  margin: auto;

  .menu {
    padding: 0 !important;
    margin: 0 !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-around;

    ul {
      // display: flex;
      // justify-content: space-evenly;
      // padding: 0 !important;
      // margin: 0 !important;
      @media (max-width: 375px) {
        display: none;
      }
    }
  }
  // .current-menu-item a{
  //   margin-bottom: 0;
  //   border-bottom: 5px solid #25abe0;
  //   padding: 0 0 12px;
  //   border-radius: 3px;
  // } 
}


.nav-shadow {
  & {
    position: relative;
    /*bottom: -10px;*/
  }
  &:after {
    content: "";
    position: relative;
    z-index: -1;
    bottom: -1px;
    -webkit-transform: scale(0.988);
    -o-transform: scale(0.988);
    transform: scale(0.988);
    -webkit-box-shadow: 0px -4px 8px 1px #000000;
    box-shadow: 0px -4px 8px 1px #000000;
    display: flex;
    @media (max-width: 375px) {
      -webkit-transform: scale(0.96);
      -o-transform: scale(0.96);
      transform: scale(0.96);
    }
  }
}


nav { //saerch nav tag
  .search-menu {
    justify-content: flex-end;
    // margin-right: 90px;
  }
}


.fa-search {
  color: #fff;
  width: 40px;
  height: 40px; 
  position: relative;
  background-color: #33acde;
  border-radius: 50%;
  text-align-last: center;
  padding-top: 11.5px;
  transition: 0.3s ease-in-out;
  &:before {
    position: absolute;
    top: 11px;
    left: 9.5px;
  }
  &:hover{
    box-shadow: 0 0 0 4px #ccdeed;
    transition: box-shadow .2s;
  }
  @media (max-width: 768px) {
    background-color: #ebebeb;
  }
}
.fa-bars {  
  color: #fff;
  min-width: 36px;
  min-height: 36px;
  position: relative;
  transition: 0.3s ease-in-out;
  background-color: #25abe0;
  border-radius: 50%;
  text-align-last: center;
  padding: 10px;    
}


//vue.js old register+signIn
#auth-nav{
  menu {
    margin-bottom: 0 !important;
  }
  .user__sign-in{    
    padding: 0 20px 0 0;
  }
  .user__sign-up {
    padding: 0 20px 0 0; 

    &:after {
      content: "/" !important;
      color: #fff;
      position: relative;
      right: -10px;
    }

    //&:hover{
    //  &:after{
    //    color: #fff;
    //    text-decoration: none;
    //  }
    //}
  }
  ul {
    padding: 0;
  }
  ul .menu-item {
    margin: 0;
  }
  ul .nav-item {
    margin: 0;
  }
  ul ul{
    display: flex;
    width: max-content;
  }
 
  li a {

  }
}









/*Anupam khosla styles from here*/
.container#search-inner {
  padding: 30px 30px 30px; 
}
.modal-open {
  /*For some reason, html elements is set to scroll, usually body element scrolls
    thats why padding-right in important  */
  padding-right: 0!important;
}
  
.modal {
  & {
    padding-right: 0!important;
  }  
  .form-group {
    margin-bottom: 20px;
    display: flex;
    column-gap: 40px;
  }
  .form-check {
    margin-bottom: 60px;
    padding-left: 0;
    display: flex;
  }
  .form-check-input[type=checkbox] {
    width: 30px;
    height: 30px;
    border-radius: 8px;
    margin-right: 25px;
  }
  label {
    & {
      align-self: center;
      width: 50%;
    }
    select.custom-select {
      width: 100%;
    }
  }

  .forms {
    h3 {
      margin-bottom: 24px;
    }
  }
}

//top blue menu 
#header-bar{
  & {
    background-color: $blue;
  }
  & > .container {
    max-width: 1320px;
    padding: 0 24px 0 30px;
  }
}
.primary-menu {
  & {
    flex-direction: row;
    flex-grow: 0;
  }
  .navbar-nav {
    margin-left: auto;
  }
  li {
    margin-right: 40px;
    display: inline-flex;
    align-items: center;
  }
}
.navbar-light .navbar-nav .nav-link {
  color: #fff;
}


//Seach navigation Menu
.sub-menu {
  display: none;
}
.menu-item:focus .sub-menu, 
.menu-item:hover .sub-menu {
  display: block;
}
#search-bar .circle-search {
  background-color: #33acde;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  margin-left: 34px; //8px more than padding left on menu-item
}
#bs-example-navbar-collapse-1 {
  padding-top: 10px;
}
#menu-search-menu {
  flex-wrap: nowrap; //In case client puts long menu names
  align-items: flex-start;
  min-height: 51px; //menu items push to top, when long names are used, this will keep the mega menu(nav-bg) in right place  
}






//Mega menu  
.nav-bg {
  position: absolute;
  left: 70px;
  right: 60px;
  top: 158px;
  background-color: #fff;    
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%); //material level 2 shadow
  border-radius: 30px;
  z-index: 101; //IMP change dorpdown z-indexes; dropdowns currently have z-index: 1000
  height: 0; //Will be dynamic with js as per dropdown height
  padding: 0;
}
#search-bar {
  .dropdown {
    position: static;
  }
  #menu-search-menu > .menu-item {
    //focusable tooltip adjustement
    padding: 0 0 0 26px;
    flex-wrap: nowrap;
    margin-right: 48px; 
  }
  .menu-item {
    & {
      display: flex;
      z-index: 103;  // nav-bg has 102  
    }
    & > a.dropdown-item {
      border-radius: 10px;
      position: relative;
    }
    & > a.dropdown-item span:after {
      content: "";
      height: 2px;
      width: 100%;
      background-color: $light-blue;
      position: absolute;
      bottom: 0;
      left: 0;
    }
    &:hover > a.dropdown-item span:after,
    &:focus > a.dropdown-item span:after {
      display: none;
    }
    &.show > a.dropdown-item {
      background-color: $blue;
      color: #fff;
    } 
    &.show > a.dropdown-item span:after{
      display: none;
    } 
    
  }  
  .dropdown-menu {
    & {
      padding: 0;
      border: 0;
      left: 50%;
      transform: translate(-100%, 89px);
      top: auto; //50px from nav-bg top 
      width: 290px;                
    }   
    .menu-item {      
      & {        
        border-radius: 10px;
      }
      .dropdown-item:hover, .dropdown-item:focus{
        color: #fff;
        background-color: $blue;
      }
    }      
    .focuseable {      
      position: absolute;
      right: 10px;      
      transform: translateY(13px);  
      color: #fff;    
    }
    .focuseable:focus {
      color: $blue;
    }
   
    .menu-item.show {       
      .focuseable {
        color: #fff;
      }   
    }       
    .dropdown-menu {
      & {
        transform: none;
        left: calc(100% + 40px);        
        top: 0;
        width: 290px;
        padding: 30px;
        background-color: $light-blue;
        border-radius: 30px;
      }
      .menu-item {
        margin-bottom: 25px;
      }      
      .menu-item:last-child {
        margin-bottom: 0;
      }
      a.dropdown-item {
        padding: 0;
        border-radius: 0;        
        display: inline;
      }    
      .dropdown-item:hover, .dropdown-item:focus{
        color: $blue;
        background-color: transparent;
      }
    }
  }  
  .dropdown-item {
    white-space: normal;
    padding: 20px 30px;
    margin-bottom: 2px;
  }
  .dropdown-toggle::after {
    display: none;
  }
  .dropdown-toggle {
    width: 0;
    height: 0;
    position: absolute;
  }
  .focuseable {
    opacity: 0;
    color: $blue;
    pointer-events: none; //mouse click disabled - only tab works
  }  
  
  .oxide-dropdown-toggle:focus-visible ~ .focuseable {
    opacity: 1;
  }
  .focuseable:focus-visible {
    opacity: 1;
  }
  .focuseable::after {
    display: inline-block;    
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }  
}
#menu-search-menu {
  & > .dropdown > a.dropdown-item:hover,
  & > .menu-item a.dropdown-item:focus {      
    background-color: $blue;
    color: #fff;
  } 
}
.active-bar {
  position: absolute;
  height: 4px;
  background-color: $blue;
  width: 0;
  transform: translateY(47px);
}






//Register sign-in
.modal {   
  .wp-block-separator.is-style-wide {
    margin: 40px auto;
  }
  p {
    margin-bottom: 32px; //2rem
  }
}
.choose-type  .wp-block-button__link {
  width: 100%;
  font-weight: 600;
}  
.conditions {
  .wp-block-button__link {
    min-width: 280px;
    margin-right: 40px;
  } 
  .btn-pm {
    margin-top: 45px;
  }
}
.register .btn {
  font-size: 14px;
}




//naviagtion







@media only screen and (hover: none) { //touch devices, tab, phone
  #search-bar .focuseable {
    opacity: 1;
    pointer-events: auto;
  }
  #search-bar .dropdown-menu .focuseable {
    color: $blue;
  }
}


@media only screen and (max-width: 1399.8px) {
  #search-bar {  
    #menu-search-menu > .menu-item {      
      margin-right: 20px; 
    }
  }
}

@media only screen and (max-width: 1199.8px) {  

  //iToc login hidden on small devices  
  #auth-nav {
    
  }

  #search-inner .navbar-brand img {
    width: 110px;    
  }
  #search-bar .circle-search {   
    margin-left: 15px; //8px more than padding left on menu-item
  }
  #search-bar {  
    #menu-search-menu > .menu-item {      
      margin-right: 0px; 
      padding: 0 0 0 7px;
    }
  }
}

@media only screen and (max-width: 991.8px) {
  .navbar-nav .nav-link {
    padding: 0;
  }
  .nav-bg {
    display: none;
  }
  #common-header {
    & {
      display: flex;
      flex-direction: column;
    }
    & {
      content: "";      
      -webkit-box-shadow: 0px -4px 8px 1px #000000;
      box-shadow: 0px -4px 8px 1px #000000;        
    }
  }  
  #header-bar {
    & {
      order: 1;
      background-color: transparent;
    }
    & > .container {
      padding: 0 30px 32px;
    }
    a, span {
      color: $blue;      
    }
  }
  #header-inner {
    & {
      margin-right: auto;
      border-top: 1px solid $gray-hr;
      padding-top: 15px; //10px gap top from li eleemnt
    }
    & > nav {
      align-items: flex-start;
    }
    .dh-tag {
      margin: 10px auto 10px 0;
      display: block;
    }
    span.dh-tag, span.dh-tag a, a.nav-link {
      color: $blue;
    }
    .menu-item {
      margin: 0 0 10px 0;
    }
    
    #register {
      margin: 0 0 10px 0 !important;
      /*Wierd bug, bootstrap modal causes normal margin to not work!*/
    }
    #register span,  
    #signIn span {
      line-height: 17px;
    }
  }
  #primary-menu-container {
    & > .nav {
      flex-direction: row;
      flex-wrap: wrap;      
    }
    .menu-item {
      width: 100%;
      min-height: 25px;
    }
    button.menu-item {
      width: auto;
    }
    li.nav-link { // slash
      width: auto;
      margin: 0 10px 10px;  
      color: $blue;
    }

  }
  #search-bar {
    #menu-search-menu > .menu-item {          
      padding: 0;
    }
    .focuseable {
      margin-left: auto;
      padding: 2px 6px;
      opacity: 1;
      color: $blue;
      align-self: center;
      pointer-events: auto;
    }
    .dropdown-menu {
      & {
        position: static;
        transform: none;
        width: 100%;
        margin-top: 12px;
        padding-left: 15px;
        background-color: transparent;
      }
      .focuseable { //need more specificity
        position: static;
        transform: none;
        color: $blue;
      }
      .menu-item {
        .dropdown-item:focus,
        .dropdown-item:hover {
          color: $blue;
          background-color: transparent;
        }
      }  
      .menu-item.show .focuseable {
        color: $blue;
      }
      .dropdown-menu {
        & {
          position: static;
          width: 100%;
          background-color: transparent;
          padding: 0 0 0 15px;
        }
        .menu-item {
          margin-bottom: 0;
        }
      }
      .menu-item {
        border-bottom: 0;
      }
    }  
    
    .menu-item {
      & > a.dropdown-item span:after {
        display: none;
      }
    }

    .menu-item.show {
      & > a.dropdown-item {
        background-color: transparent;
        color: $blue;
      }
      & > .focuseable {
        color: $blue;
      }
    }
    .dropdown-item {
      padding: 0;
      width: auto;
      align-self: center;
    }  
    .nav-link {
      padding: 0;
      align-self: center;
    }  
    #bs-example-navbar-collapse-1 {
        .menu-item { // need 3 level nesting
          flex-wrap: wrap;
          width: 100%;
          margin: 0 0 10px 0;
          min-height: 25px;
      }
    }
  }
  #bs-example-navbar-collapse-1 {
    padding-top: 0;
  }
  #bs-example-navbar-collapse-1 > ul > .current-menu-item {
      & {
        position: relative;
      }
      &::before {
      content: "";
      width: 4px;
      height: 100%;
      background-color: $blue;
      position: absolute;
      left: -30px;
    }
  }
  
  .container#search-inner {
    padding: 30px 30px 15px;
  }
  #menu-search-menu {
    & {
      height: auto;
      padding-top: 30px;
      align-items: flex-start;
    }    
  }
  .nav-shadow:after {
    display: none;
  }
}


//igonre print and match bootstrap query
@media only screen and (max-width: 767.8px) {
  #search-bar {
    
  }
}

@media only screen and (max-width: 575.8px) {
  .container#search-inner {
    padding: 15px; 
  }
  #header-bar > .container {
    padding: 0 15px 20px;
  }
  #menu-search-menu {
    padding-top: 15px;
  }
  #search-bar #menu-search-menu .menu-item {
    margin: 0 0 8px 0;
  }
  #search-bar .dropdown-menu {
    margin-top: 8px;
  }
  #bs-example-navbar-collapse-1 > ul > .current-menu-item::before {
    content: "";
    width: 4px;
    height: 100%;
    background-color: $blue;
    position: absolute;
    left: -15px;
  }
  #header-inner .dh-tag {
    margin: 8px auto 8px 0;
  }
  #header-inner .menu-item {
    margin: 0 0 8px 0;
  }
  #header-inner #register {
    margin: 0 0 8px 0 !important;
  }
  #primary-menu-container li.nav-link {
    margin: 0 10px 8px;
  }
}