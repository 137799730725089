//reusable block styles

/*older scss below has been modified tomremove repaeted rules*/
.components{
  h1{
    font-family: 'Inter', sans-serif;        
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
  }
  h2{
    font-family: 'Inter', sans-serif;
    font-size: 30px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    // line-height: 1.43; // AK commented out
    letter-spacing: normal;
    text-align: left;
    color: #015ba6;
  }
  h4{
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.28;
    letter-spacing: normal;
    text-align: left;
  }

  span{
    // font-family: 'Inter', sans-serif;
    // font-size: 14px !important;
    // font-stretch: normal;
    // font-style: normal;
    // line-height: 1.79;
    // letter-spacing: normal;
    // text-align: left;
  }

  a{
    // font-family: 'Inter', sans-serif;
    /* font-size: 14px; */
    /* font-weight: bold; */
    font-stretch: normal;
    font-style: normal;
    /* line-height: 1.64; */
    // letter-spacing: normal;
    // text-align: left;
    // color: #015ba6;   
    /* text-decoration: underline; */
    &:hover{
      /*text-decoration: none;*/ /*AK commented out*/
    }   
  }

  button{
    /*
    border-radius: 30px;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.64;
    letter-spacing: normal;
    text-align: center;
    transition: 0.3s 0s ease-in-out;
    */
   
  }

  figure{
    .play-video{
      top: -4rem;
      position: relative;
      font-size: 1rem !important;
      font-weight: 600;
      text-decoration: underline;
      &:hover{
        text-decoration: none;
      }
    }
  }

}

.feature-img-w-text{
  tr{
    display: flex;
    align-items: center;
  }
  td{
    display: flex;
    flex-direction: column;
    border: 0px !important;
    width: 50%;
    &:first-child{
      margin-right: 100px;
    }
  }
  img{
    max-width: 549px;
    width: 100%;
    max-height: 360px;
    height: auto;
    margin: 43.5px 0 108px 88px;
    border-radius: 20px;
  }
  .img-rectangle{
    max-width: 519px;
    width: 100%;
    max-height: 300px;
    height: auto;
    margin: 110.5px 0 107px 160px;
    opacity: 0.9;
    border-radius: 30px;
    background-color: #e9f7fc;
    position: absolute;
    z-index: -1;
  }
  h1{
    color: #015ba6;
  }
  span{
    font-weight: normal;
    color: #333;
  }
  button{
    width: 243.6px;
    height: 50px;
    margin: 0px 17.7px 8px 0px;
    padding: 14px 3.5px 13px 3.5px;
    background-color: #015ba6;
    color: #fff;
    &:hover{
      box-shadow: 0 0 0 4px #ccdeed;
      transition: box-shadow .2s;
    }
  }

  @media only screen and (min-width:376px) and (max-width:600px){
    tr{
      align-items: center;
      flex-direction: column;
    }
    td{
      margin: 0;
      width: 100%;
    }
    img{
      margin: 0;
    }
    button{
      width: 100%;
      margin: 0 0 16px 0;
    }
  }

  @media (max-width:375px){
    tr{
      align-items: center;
      flex-direction: column;
    }
    td{
      margin: 0;
      width: 100%;
    }
    img{
      margin: 0;
    }
    button{
      width: 100%;
      margin: 0 0 16px 0;
    }
  }
}

.text-media-block{
  padding: 38px 77px 28px 103px;
  border-radius: 30px;
  background-color: #e9f7fc;
  margin: 14px 0;
  justify-items: end;
  a{
    margin: 16px 0 118px 0;
  }
  .wp-block-media-text__content{
    padding-left: 0px !important;
  }
  .has-large-font-size{
    margin: 62px 0 40px 0;
    font-family: 'Inter', sans-serif;
    font-size: 30px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #015ba6;
  }
  p{
    margin: 40px 0 16px 0;
    color: #333;
  }
  figure{
    max-width: 580px;
    width: 100%;
    max-height: 345px;
    height: auto;
    margin: 0 0 18px 82px;
    img{
      box-shadow: 0 5.3px 5.3px 0 rgba(0, 0, 0, 0.15);
      margin-bottom: 10px;
    }
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.64;
    letter-spacing: normal;
    text-align: center;
    color: #015ba6;
    text-decoration: underline;
    &::after{
      content: "Play Video";
    }
  }



  @media only screen and (min-width:376px) and (max-width:768px){
    padding: 0;
    .wp-block-group__inner-container{
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 47px 30.9px 66px 30.9px;
    }
    .wp-block-media-text__content{
      padding: 0;
    }
    .wp-block-media-text__media{
      padding: 0 27px 0 0;
    }
    .has-large-font-size{
      margin: 0 7.125rem 2.5rem 0;
    }
    p{
      margin: 0 0 2.5rem 0;
    }
    figure{
      width: 100%;
      height: auto;
    }
    a{
      margin: 54px 2.1px 16px 16.1px;
    }
  }

  @media (max-width:375px){
    padding: 0;
    .wp-block-group__inner-container{
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 47px 30.9px 66px 30.9px;
    }
    .wp-block-media-text__content{
      padding: 0;
    }
    .has-large-font-size{
      margin: 0 7.125rem 2.5rem 0;
    }
    p{
      margin: 0 0 2.5rem 0;
    }
    figure{
      width: 100%;
      height: auto;
    }
    a{
      margin: 54px 2.1px 16px 16.1px;
    }
  }
}

.text-button-block{


  h2{
    margin: 0;
    padding: 0px;
    font-family: 'Inter', sans-serif;
    font-size: 30px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #015ba6;
  }
  

  button{
    max-width: 416px;
    width: 100%;
    height: 72px;
    padding: 27px 33px 27px 37px;
    background-color: #e9f7fc;
    float: right;
    color: #015ba6;
    &:hover{
      box-shadow: 0 0 0 4px #ccdeed;
      transition: box-shadow .2s;
    }
  }
    // .wp-block-column {
    //     align-self: center;
    // }
    .text-button-block-rectangle{
      width: 110px;
      height: 11px;
      margin: 70px 0 55px 0;
      border-radius: 40px;
      background-color: #e9f7fc;
    }

    @media only screen and (min-width:600px) and (max-width:768px){
      flex-direction: row;
      .wp-block-column{
        flex-basis: 60% !important;
      }
      p{
        width: auto;
      }
    }

    @media only screen and (min-width:376px) and (max-width:600px) {
      margin: 14px 0 14px 0;
      button{
        display: none;
      }
      p{
        width: auto;
      }
      padding: 0px;
    }

    @media (max-width:375px){
      margin: 14px 0 14px 0;
      button{
        display: none;
      }
      p{
        width: auto;
      }
      padding: 0;
    }
  }

  .four-card-block{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: -3rem;
    .four-card-item{
      display: flex;
      flex-direction: column;
      justify-content: center;
        // width: 49%;
        height: 242px;
        margin: 0 0 27px 0;
        padding: 42px 52.2px 36px 52.2px;
        border-radius: 30px;
        background-color: #e9f7fc;
        transition: box-shadow .2s;
        &:last-of-type{
          margin: 0;
        }
        a{
          font-size: 18px;    
          text-decoration: none;
          color:#003864;
        }
        &:hover{
          box-shadow: 0 0 0 6px #b3cee4;
          transition: box-shadow .2s;
        }
      }
      h4{
        color: #003864;
      }
      p{
        margin: 14px 0 8px;
        color: #003864;
      }


      @media only screen and (min-width:376px) and (max-width:768px){
        flex-direction: column;
        .wp-block-column:nth-child(2n){
          margin-top: 2rem;
          margin-left: 0;
        }
        .four-card-item{
          width: 100%;
          height: auto;
          h4{
            margin: 0;
          }
        }
      }

      @media (max-width:375px){
        flex-direction: column;
        .wp-block-column:nth-child(2n){
          margin-top: 2rem;
          margin-left: 0;
        }
        .four-card-item{
          width: 100%;
          height: auto;
          h4{
            margin: 0;
          }
        }
      }
    }

    .three-card-block{
      & {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        grid-gap: 28px;
      }

      .three-card-item{
        //height: 436px;
        padding: 48px 65.4px 56px 65.4px;
        /*opacity: 0.89;*/
        border-radius: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #e9f7fc;
        &:not(:last-child){
          // margin-right: 32px;
        }
      }
      h3{
        font-family: 'Inter', sans-serif;
        font-size: 1.125rem;
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.67;
        letter-spacing: normal;
        text-align: left;
        color: #015ba6;
        
      }
      h6{
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.79;
        letter-spacing: normal;
        text-align: left;
        color: #333;
      }
      p{
        color: #333;
      }
      button{
        max-width: 248.9px;
        width: 100%;
        color: #fff;
        max-height: 50px;
        height: 100%;
        margin: 36px auto 0;
        padding: 13px 21.3px 14px 29.8px;
        background-color: #015ba6;
        &:hover{
          box-shadow: 0 0 0 4px #ccdeed;
          transition: box-shadow .2s;
        }
        &:after{
          content: "";
          float: right;
          padding-right: 16px;
          background-image: url(./assets/img/link-arrow.svg);
        }
      }

      .wp-block-column:nth-child(2n){
        margin-left: 0 !important;
      }
      .wp-block-column:not(:first-child){
        margin-left: 0 !important;
      }

      @media only screen and (min-width:376px) and (max-width:768px){
        flex-direction: column;
        .three-card-item{
          width: 100%;
          margin: 0 0 24px 0;
        }
      }

      @media (max-width:375px){
        .three-card-item{
          justify-content: space-around;
          margin: 0 0 1.6875rem 0;
          padding: 32px 28.7px 34px 28.7px;
        }
        button{
          margin: 2.25rem 0 0 0;
          width: 100%;
        }
      }
    }

    .double-text-block{
      display: flex;
      justify-content: space-between;
      margin: 16px 0;
      flex-wrap: wrap;

      h3{
        font-family: 'Inter', sans-serif;
        font-size: 30px !important;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: left;
        color: #015ba6;
      }
      p{
        color: #003864;      
      }

      .double-text-block-rectangle{
        width: 108.3px;
        height: 11px;
        margin: 0 476.6px 55px 0px;
        border-radius: 40px;
        background-color: #e9f7fc;
      }

      .double-text-block-item{
        max-width: 641px;
        width: 100%;
        padding: 49px 87px 40px 56px;
      }

      h4{
        margin: 0 0 32px 4.9px;
        color: #015ba6;
      }

      span{
        font-weight: 600;
        color: #333;
      }

      .double-text-card-item{
        max-width: 641px;
        width: 100%;
        height: 310px;
        margin: 24px 0 0 0;
        padding: 49px 56px 40px 56px;
        opacity: 0.89;
        border-radius: 30px;
        background-color: #e9f7fc;
        display: flex;
        flex-direction: column;
        justify-content: center;
        a{
          font-size: 18px;
          color: #003864;
        }
      }

      @media only screen and (min-width:376px) and (max-width:1440px){
        justify-content: space-around;
        .double-text-block-item{
          width: 100%;
        }
        .double-text-card-item{
          width: 100%;
        }
        #item-1{
          order: 1 !important;
        }
        #item-2{
          order: 3 !important;
        }
        #item-3{
          order: 2 !important;
        }
        #item-4{
          order: 4 !important;
        }
      }

      @media (max-width:375px){
        .double-text-block-item{
          width: 100%;
          margin: 24px 0 24px 11px;
          height: 100%;
          padding: 0;
          p{
            width: 100%;
          }
        }

        .double-text-card-item{
          width: 100%;
          p{
            width: 100%;
          }
        }
        #item-1{
          order: 1 !important;
        }
        #item-2{
          order: 3 !important;
        }
        #item-3{
          order: 2 !important;
        }
        #item-4{
          order: 4 !important;
        }
        h4{
          width: 100%;
          height: auto;
        }
        button{
          margin: 2.25rem 0 0 0;
          width: 100%;
        }
      }
    }

    .paragraph-card-blue, p.paragraph-card-blue{
      max-width: 572px;
      width: 100%;
      padding: 33px 59px 33px 59px;
      opacity: 0.9;
      border-radius: 30px;
      background-color: #e9f7fc;
      margin-top: 48px;
      @media screen and (max-width: 768px) {
        max-width: initial;
      }
    }

    .paragraph-card-clear {
      max-width: 572px;
      width: 100%;
      padding: 33px 59px 33px 59px;

      @media screen and (max-width: 768px) {
        padding: 48px 0 0 0;
        max-width: initial;
      }
    }

    .media-right{
      .wp-block-embed__wrapper{
        box-shadow: 0 5.3px 5.3px 0 rgb(0 0 0 / 15%);
        margin: 10px 0 0 0;
        max-width: 440px;
        width: 100%;
        max-height: 255px;
        float: right;
        position: relative;
        top: -100px;
        left: -45px;
      }
      text-align: center;
      color: #015ba6 !important;
      @media (max-width:1350px){
        position: initial;
        float: right;
        padding: 0 40px;
        margin: 3rem 0 !important;
      }
      @media (max-width: 375px){
        padding: 0;
      }
    }

    .side-menu-list{
      max-width: 275px;
      width: 100%;
      float: right;
      padding-bottom: 24px;
      ul{padding: 0;margin: 0;}
      ul ul{border-bottom: 2px solid #e9f7fc;padding-bottom: 20px !important;}
      span{color: #003864 !important;}
      ul > ul > span{padding: 20px 0;opacity: 0.9;}
      a{
        color: #015ba6 !important;
        padding: 20px 0;
        border-bottom: 2px solid #e9f7fc;
      }
      ul > ul > a {
        border-left: 1px solid #015ba6;
        margin-left: 16px !important;
        padding-left: 16px !important;
        border-bottom: none;
      }
      @media (max-width:1024px){
        float: none;
      }
      a:hover {
        text-decoration: underline !important;
      }
    }

    .double-text-card{
      display: flex;
      justify-content: space-between;
      margin: 16px 0;
      flex-wrap: wrap;

      h3{
        font-family: 'Inter', sans-serif;
        font-size: 30px !important;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: left;
        color: #015ba6;
      }
      p{
        color: #003864;
      }

      .double-text-block-rectangle{
        width: 108.3px;
        height: 11px;
        margin: 0 476.6px 55px 0px;
        border-radius: 40px;
        background-color: #e9f7fc;
      }

      .double-text-block-item{
        max-width: 641px;
        width: 100%;
        padding: 49px 87px 40px 56px;
      }

      h4{
        margin: 0 0 32px 4.9px;
        color: #015ba6;
      }

      span{
        font-weight: 600;
        color: #333;
      }

      li:hover{
        box-shadow: 0 0 0 6px #b3cee4;
        transition: box-shadow .2s;
      }

      .double-text-card-item, li{
        max-width: 641px;
        width: 100%;
        //height: 310px;
        margin: 24px 0 0 0;
        padding: 49px 56px 40px 56px;
        opacity: 0.89;
        border-radius: 30px;
        background-color: #e9f7fc;
        display: flex;
        flex-direction: column;
        justify-content: center;
        transition: box-shadow .2s;
        a{
          font-size: 18px;
          color: #003864 !important;
          text-decoration: none;
        }
      }

      @media only screen and (min-width:376px) and (max-width:1440px){
        justify-content: space-around;
        .double-text-block-item{
          width: 100%;
        }
        .double-text-card-item, li{
          width: 100%;
        }
        #item-1{
          order: 1 !important;
        }
        #item-2{
          order: 3 !important;
        }
        #item-3{
          order: 2 !important;
        }
        #item-4{
          order: 4 !important;
        }
      }

      @media (max-width:375px){
        .double-text-block-item{
          width: 100%;
          margin: 24px 0 24px 11px;
          height: 100%;
          padding: 0;
          p{
            width: 100%;
          }
        }

        .double-text-card-item, li{
          width: 100%;
          p{
            width: 100%;
          }
        }
        #item-1{
          order: 1 !important;
        }
        #item-2{
          order: 3 !important;
        }
        #item-3{
          order: 2 !important;
        }
        #item-4{
          order: 4 !important;
        }
        h4{
          width: 100%;
          height: auto;
        }
        button{
          margin: 2.25rem 0 0 0;
          width: 100%;
        }
      }
    }

    .menu-list-card{
      display: flex;
      flex-direction: column;
      width: fit-content;
      padding: 33px 59px 33px 59px;
      opacity: 0.9;
      border-radius: 30px;
      background-color: #e9f7fc;
      margin-top: 48px;
      a{
        margin: 0;
        padding: 8px 0;
        color: #003864 !important;
        &:hover{
          text-decoration: underline;
        }
      }
      ul{padding: 0;margin: 0;}
      span{color: #003864 !important; padding: 20px 0;}
      ul > a {
        border-left: 1px solid #015ba6;
        margin: 0 0 0 16px !important;
        padding-left: 16px !important;
        border-bottom: none;
      }
      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }

    .download-block{
      padding: 48px 65.4px 56px 65.4px;
      opacity: 0.89;
      border-radius: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: #e9f7fc;
      h3{
        font-family: 'Inter', sans-serif;
        font-size: 18px !important;
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.67;
        letter-spacing: normal;
        text-align: left;
        color: #015ba6;
        margin-bottom: 16px;
      }
      #download-item{
        margin: 24px 0;
        padding: 24px 0;
        flex-direction: row;
        &:not(:first-of-type){
          border-top: 2px solid #c6dfef;
        }
        @media screen and (max-width: 768px) {
          border-top: 2px solid #c6dfef;
          flex-direction: column;
          button{
            margin-top: 16px;
          }
        }
      }
      p{
        color: #333;
        margin: 0;
      }
      button{
        max-width: 248.9px;
        width: 100%;
        color: #fff;
        max-height: 50px;
        height: 100%;
        padding: 13px 21.3px 14px 29.8px;
        background-color: #015ba6;
      }
    }

    .download-block-wide{
      padding: 48px 0 0 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      .text-button-block-rectangle{
        width: 110px;
        height: 11px;
        margin: 70px 0 55px 0;
        border-radius: 40px;
        background-color: #e9f7fc;
      }
      h3{
        color: #015ba6;
        margin-bottom: 16px;
      }
      #download-item{
        margin: 24px 0;
        padding: 24px 0;
      }
      p{
        color: #333;
        margin: 0;
      }
      button{
        max-width: 248.9px;
        color: #fff;
        max-height: 50px;
        height: 100%;
        padding: 13px 21.3px 14px 29.8px;
        background-color: #015ba6;
        &:hover{
          background-color: #e9f7fc;
          color: #015ba6;
        }
      }
    }

    .item-grid{
      padding-bottom: 48px;
      padding-left: 52px;
      h4{
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.67;
        letter-spacing: normal;
        text-align: left;
        color: #003864 !important;
      }
      .col-item{
        background-color: #e9f7fc;
        color: #015ba6;
        border-radius: 30px;
        width: 100%;
        text-align: center;
        padding: 10px 24px;
        margin: 8px 0 0 0;
        white-space: nowrap;
        transition: 0.3s 0s ease-in-out;
        &:hover{
          background-color: #015ba6;
          color: #e9f7fc;
        }
      }
      .col{
        flex-grow: 1;
        padding: 0 8px 0 0 !important;
      }
      @media screen and (max-width: 768px) {
        padding-left: 0;
        .row{
          flex-direction: column;
          margin: 0;
        }
      }
    }

    .text-list-block{
      padding: 38px 77px 28px 103px;
      border-radius: 30px;
      background-color: #e9f7fc;
      margin: 14px 0;
      justify-items: end;
      ul{padding: 0;margin: 0;}
      a, li{
        margin: 0;
        padding: 8px 0;
        font-family: "inter", sans-serif;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #015ba6 !important;
        list-style-type: none;
        &:not(:last-child) {
          border-bottom: 2px solid white;
          max-width: 250px;
          width: 100%;
        }
        &:hover{
          text-decoration: underline;
        }
      }
      .has-large-font-size{
        margin: 62px 0 62px 0;
        font-family: 'Inter', sans-serif;
        font-size: 30px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: left;
        color: #015ba6;
      }
    }

    .col-block{
      .text-button-block-rectangle{
        width: 110px;
        height: 11px;
        margin: 70px 0 55px 0;
        border-radius: 40px;
        background-color: #e9f7fc;
      }
      p{
        font-size: 14px;
        color: $dark-blue;
      }
      h4, h2{
        font-weight: 900;
      }
      a{
        color: $blue;
        font-weight: bold;
        text-decoration: underline;
        transition: color ease 0.3s;
      }
      // a.wp-block-button__link:focus,
      // a.wp-block-button__link:hover {
      //   color: #fff;
      // }
    }

    .news-and-post-grid{
      article{
        padding: 48px 26.4px 56px 65.4px;
        opacity: 0.89;
        border-radius: 30px;
        background-color: #e9f7fc;
        header{
          background-color: transparent;
          h3.gb-block-post-grid-title{
            a{
              font-size: 18px !important;
              font-weight: 800;
              color: #015ba6  !important;
            }
          }
          time{
            font-weight: 600;
            line-height: 1.79;
            color: #333;
          }
        }
        @media screen and (max-width: 375px) {
          padding: 48px;
        }
      }
    }

    .recent-updates-block{
      .text-button-block-rectangle{
        width: 110px;
        height: 11px;
        margin: 70px 0 55px 0;
        border-radius: 40px;
        background-color: #e9f7fc;
      }
      h2{
        font-size: 30px;
        font-weight: 900;
        line-height: 1.43;
        color: #015ba6;
      }
      ul{
        padding: 0;
      }
      .featuredpost article{margin-right: 40px;}
      .featuredpost article .gb-block-post-grid-text{
        padding: 40px 0;
        opacity: 1;
        border-radius: 0px;
        background-color: transparent;

        a{
          font-size: 18px;
          color: #015ba6;
        }

        .gb-text-link{
          padding: 14px 10px 13px 26px;
          border-radius: 30px;
          background-color: #015ba6;
          color: white;
          width: 40%;
          font-size: 14px;
          &:after{
            content: "";
            height: 24px;
            width: 60px;
            background-image: url(./assets/img/icons/arrow-down-solid.svg);
          }
        }
      }
      .second .gb-block-layout-column-inner{
        ul{
          padding: 30px;
          border-radius: 30px;
          background-color: #e9f7fc;
          a{color: #015ba6;}
        }
      }
    }

    .webinar-block{
      h2{
        font-size: 30px;
        font-weight: 900;
        line-height: 1.43;
        color: #015ba6;
      }
      h4{
        font-size: 18px;
        font-weight: 800;
        line-height: 1.67;
        color: #015ba6;
      }
      .text-button-block-rectangle{
        width: 110px;
        height: 11px;
        margin: 70px 0 55px 0;
        border-radius: 40px;
        background-color: #e9f7fc;
      }
      .featuredpost article .gb-block-post-grid-text{
        padding: 41px 43px 40px 37px;
        opacity: 0.89;
        border-radius: 30px;
        background-color: #e9f7fc;
        a{
          font-size: 18px;
          font-weight: 800;
          line-height: 1.67;
          color: #015ba6;
        }
      }

    }

    .glossary-block{
      h2{
        font-size: 30px;
        font-weight: 900;
        line-height: 1.43;
        color: #015ba6;
      }
      h4{
        font-size: 18px;
        font-weight: 800;
        line-height: 1.67;
        color: #015ba6;
      }
      .text-button-block-rectangle{
        width: 110px;
        height: 11px;
        margin: 70px 0 55px 0;
        border-radius: 40px;
        background-color: #e9f7fc;
      }
      .wp-block-genesis-blocks-gb-columns .gb-is-responsive-column .gb-block-layout-column{
        padding: 34px 169.2px 39px 33px;
        border-radius: 20px;
        background-color: #e9f7fc;
        a{
          background-color: transparent;
          color: #015ba6;
          text-decoration: underline;
        }
        h5{
          font-size: 16px;
          font-weight: bold;
          line-height: normal;
          color: #015ba6;
        }
        div{
          margin: 0;
        }
      }
    }

    .nav-block{
      max-width: 275px;
      width: 100%;
      float: right;
      padding-bottom: 24px;
      ul{padding: 0;margin: 0;list-style: none;}
      ul li{
        border-bottom: 2px solid #e9f7fc;
        padding: 20px 0 !important;
        &:first-child{
          padding: 0;
        }
      }
      span, p{color: #003864 !important;}
      ul > li  span, ul > li  p{padding: 20px 0;opacity: 0.6;}
      a{
        color: #015ba6 !important;
        padding: 20px 0;
      }
      ul > li > ul > li  {
        border-left: 1px solid #015ba6;
        margin-left: 16px !important;
        padding-left: 16px !important;
        border-bottom: none;
      }
      @media (max-width:1024px){
        float: none;
      }
    }





////////////////////////SIZING///////////////////////////
.r-w-25{
  width: 25%;
  @media (max-width: 1024px) {
    width: 100%;
  }
}

.r-w-50{
  width: 50%;
  @media (max-width: 1024px) {
    width: 100%;
  }
}

.r-w-75{
  width: 75%;
  @media (max-width: 1024px) {
    width: 100%;
  }
}
