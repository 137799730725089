

* {
  -webkit-font-smoothing: antialiased; /*IMP: Old style, need to reconsider antialiasing for blue text on white bg*/
  -moz-osx-font-smoothing: grayscale;
  font-family: Inter, helvetica, arial, sans-serif;
}


//global rules  

@media only screen and (max-width: 767.8px) {
  .invisible {
    display: none;
  }
  .three-card-block .three-card-item.invisible {
    display: none;
  }
}

@import "import";
  


