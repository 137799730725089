// Edited by AK oxide




.site-footer {
  background-color: #003864;
  color: #fff;
  overflow-x: hidden;

  li, a, p {
    color: #fff;
  }

  #footer-1 {
    padding: 40px 0 24px 0;
    align-items: center;
    a{
      // text-decoration: underline;
      // padding-top: 16px;
      &:hover{
        text-decoration: none;
        color: white;
      }
    }
  }

  #footer-2 {
    & {
      margin-bottom: 32px;
    }
    ul, p {      
      padding: 0 ;
      // font-size: 12px !important
    }  
    .about-news {
      margin-bottom: 25px;
    }  
  }

  #footer-3 {
    margin: 0;
    
    // border-top: 2px solid #33acde;
    div{padding: 0;}
    
    a{
      text-decoration: none;
      &:hover{
        text-decoration: underline;
        color: white;
      }
    }
  }

  .footer-menu > .menu {
    display: flex;
    justify-content: space-between;
    padding-left: 15px;
  }
}
  

 




/*AK code starts*/
.scroll-btn {
  & {
    pointer-events: none; //this will allow click to apss through
    position: sticky;
    margin-top: -20px; //matches main tag's padding bottom
    padding: 0 20px 20px 0;
    bottom: 0px;  
    color: $blue;   
    overflow: auto;
    z-index: 2;
    min-height: 40px;
  }  
}


#smoothup {
  visibility: hidden;  
  pointer-events: auto; 
  display: flex;  
  float: right;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
}
#smoothup button {
  border-radius: 50px;
  background-color: $blue;
  width: 40px;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  outline-offset: -1px;
}
#smoothup img {
  
}

footer {  
  .navbar-nav .nav-link {
    padding: 0;
    display: inline;
  }
  .dropdown-toggle::after {
    display: none;
  }
  .menu-item:not(:last-child) {
    margin-bottom: 25px;
    font-weight: 500;
  } 
  .menu-item:last-child {
    margin-bottom: 0;   
  }      
}
#menu-search-menu-1 {
  & {
    display: block;
  }
  button {
    display: none;
  }
}
.footer-menu {
   .menu-item:not(:last-child) {
      margin-bottom: 0;
   }
}
#site-footer {
  hr.wp-block-separator.thin.is-style-wide {
    border-bottom-color: #33ACDE;
  }
}
.footer-inner {
  margin: auto;
  max-width: 1260px;
}

.footer-inner.container {
  padding: 0;
}

.social-icons {
  & {
    text-align: right;
  }
  .icon {
    margin-right: 40px;
  }
}


//AK code  starts




@media only screen and (max-width: 1399.98px) {
  .scroll-btn.container {
    max-width: 1140px;     
  }
  .footer-inner {
    max-width: 1140px;
  }
}


@media only screen and (max-width: 1199.98px)  {  
  .scroll-btn.container {
    max-width: 920px;     
  }
  .footer-inner {
      max-width: 920px;
  }
  #footer-2 {    
    
  }  
}



@media only screen and (max-width: 991.98px) {
  .scroll-btn.container {
    max-width: 720px;   
    padding: 0 15px;  
  }
  .footer-inner {
    max-width: 720px;
  }
  .site-footer {
    .footer-menu > .menu {
      padding-left: 0;
    }
  }
}




@media (max-width: 767.98px) {
  .footer-inner.container {
    padding: 0 15px;
  }
  #site-footer {  
    .menu-item:last-child {
      margin-bottom: 25px;   
    }  
    .sign-in {
      margin-bottom: 25px;
    }   
    #footer-2 {
      margin-bottom: 0;
    } 
  }
  #footer_search2 ul {
    & {
      margin-bottom: 16px;
    }
    .menu-item:last-child {
      margin-bottom: 0;
    }
  }
  .footer-menu {
    & {
      padding-top: 0;
    }
    .menu-item:not(:last-child) {
      margin-bottom: 25px;
    }
  }
  #footer-1.row, #footer-3.row {
    flex-wrap: wrap !important;
  }
  .logocopy {
    margin-bottom: 24px;
    text-align: center;
  }
  #footer-3 {    
    .menu {
      padding: 0 0 7px 0;
      margin: 0;
      flex-direction: column;
    }
  }  
  .social-icons {
    text-align: center;
  }  
  .social-icons .icon:last-child {
    margin-right: 0;
  }
}



//AK code finishes
