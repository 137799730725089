@charset "UTF-8";
* {
  -webkit-font-smoothing: antialiased;
  /*IMP: Old style, need to reconsider antialiasing for blue text on white bg*/
  -moz-osx-font-smoothing: grayscale;
  font-family: Inter, helvetica, arial, sans-serif;
}

@media only screen and (max-width: 767.8px) {
  .invisible {
    display: none;
  }

  .three-card-block .three-card-item.invisible {
    display: none;
  }
}
/*older scss below has been modified tomremove repaeted rules*/
.components h1 {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
}
.components h2 {
  font-family: "Inter", sans-serif;
  font-size: 30px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #015ba6;
}
.components h4 {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: left;
}
.components a {
  /* font-size: 14px; */
  /* font-weight: bold; */
  font-stretch: normal;
  font-style: normal;
  /* line-height: 1.64; */
  /* text-decoration: underline; */
}
.components a:hover {
  /*text-decoration: none;*/
  /*AK commented out*/
}
.components button {
  /*
  border-radius: 30px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.64;
  letter-spacing: normal;
  text-align: center;
  transition: 0.3s 0s ease-in-out;
  */
}
.components figure .play-video {
  top: -4rem;
  position: relative;
  font-size: 1rem !important;
  font-weight: 600;
  text-decoration: underline;
}
.components figure .play-video:hover {
  text-decoration: none;
}

.feature-img-w-text tr {
  display: flex;
  align-items: center;
}
.feature-img-w-text td {
  display: flex;
  flex-direction: column;
  border: 0px !important;
  width: 50%;
}
.feature-img-w-text td:first-child {
  margin-right: 100px;
}
.feature-img-w-text img {
  max-width: 549px;
  width: 100%;
  max-height: 360px;
  height: auto;
  margin: 43.5px 0 108px 88px;
  border-radius: 20px;
}
.feature-img-w-text .img-rectangle {
  max-width: 519px;
  width: 100%;
  max-height: 300px;
  height: auto;
  margin: 110.5px 0 107px 160px;
  opacity: 0.9;
  border-radius: 30px;
  background-color: #e9f7fc;
  position: absolute;
  z-index: -1;
}
.feature-img-w-text h1 {
  color: #015ba6;
}
.feature-img-w-text span {
  font-weight: normal;
  color: #333;
}
.feature-img-w-text button {
  width: 243.6px;
  height: 50px;
  margin: 0px 17.7px 8px 0px;
  padding: 14px 3.5px 13px 3.5px;
  background-color: #015ba6;
  color: #fff;
}
.feature-img-w-text button:hover {
  box-shadow: 0 0 0 4px #ccdeed;
  transition: box-shadow 0.2s;
}
@media only screen and (min-width: 376px) and (max-width: 600px) {
  .feature-img-w-text tr {
    align-items: center;
    flex-direction: column;
  }
  .feature-img-w-text td {
    margin: 0;
    width: 100%;
  }
  .feature-img-w-text img {
    margin: 0;
  }
  .feature-img-w-text button {
    width: 100%;
    margin: 0 0 16px 0;
  }
}
@media (max-width: 375px) {
  .feature-img-w-text tr {
    align-items: center;
    flex-direction: column;
  }
  .feature-img-w-text td {
    margin: 0;
    width: 100%;
  }
  .feature-img-w-text img {
    margin: 0;
  }
  .feature-img-w-text button {
    width: 100%;
    margin: 0 0 16px 0;
  }
}

.text-media-block {
  padding: 38px 77px 28px 103px;
  border-radius: 30px;
  background-color: #e9f7fc;
  margin: 14px 0;
  justify-items: end;
}
.text-media-block a {
  margin: 16px 0 118px 0;
}
.text-media-block .wp-block-media-text__content {
  padding-left: 0px !important;
}
.text-media-block .has-large-font-size {
  margin: 62px 0 40px 0;
  font-family: "Inter", sans-serif;
  font-size: 30px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #015ba6;
}
.text-media-block p {
  margin: 40px 0 16px 0;
  color: #333;
}
.text-media-block figure {
  max-width: 580px;
  width: 100%;
  max-height: 345px;
  height: auto;
  margin: 0 0 18px 82px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.64;
  letter-spacing: normal;
  text-align: center;
  color: #015ba6;
  text-decoration: underline;
}
.text-media-block figure img {
  box-shadow: 0 5.3px 5.3px 0 rgba(0, 0, 0, 0.15);
  margin-bottom: 10px;
}
.text-media-block figure::after {
  content: "Play Video";
}
@media only screen and (min-width: 376px) and (max-width: 768px) {
  .text-media-block {
    padding: 0;
  }
  .text-media-block .wp-block-group__inner-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 47px 30.9px 66px 30.9px;
  }
  .text-media-block .wp-block-media-text__content {
    padding: 0;
  }
  .text-media-block .wp-block-media-text__media {
    padding: 0 27px 0 0;
  }
  .text-media-block .has-large-font-size {
    margin: 0 7.125rem 2.5rem 0;
  }
  .text-media-block p {
    margin: 0 0 2.5rem 0;
  }
  .text-media-block figure {
    width: 100%;
    height: auto;
  }
  .text-media-block a {
    margin: 54px 2.1px 16px 16.1px;
  }
}
@media (max-width: 375px) {
  .text-media-block {
    padding: 0;
  }
  .text-media-block .wp-block-group__inner-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 47px 30.9px 66px 30.9px;
  }
  .text-media-block .wp-block-media-text__content {
    padding: 0;
  }
  .text-media-block .has-large-font-size {
    margin: 0 7.125rem 2.5rem 0;
  }
  .text-media-block p {
    margin: 0 0 2.5rem 0;
  }
  .text-media-block figure {
    width: 100%;
    height: auto;
  }
  .text-media-block a {
    margin: 54px 2.1px 16px 16.1px;
  }
}

.text-button-block h2 {
  margin: 0;
  padding: 0px;
  font-family: "Inter", sans-serif;
  font-size: 30px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #015ba6;
}
.text-button-block button {
  max-width: 416px;
  width: 100%;
  height: 72px;
  padding: 27px 33px 27px 37px;
  background-color: #e9f7fc;
  float: right;
  color: #015ba6;
}
.text-button-block button:hover {
  box-shadow: 0 0 0 4px #ccdeed;
  transition: box-shadow 0.2s;
}
.text-button-block .text-button-block-rectangle {
  width: 110px;
  height: 11px;
  margin: 70px 0 55px 0;
  border-radius: 40px;
  background-color: #e9f7fc;
}
@media only screen and (min-width: 600px) and (max-width: 768px) {
  .text-button-block {
    flex-direction: row;
  }
  .text-button-block .wp-block-column {
    flex-basis: 60% !important;
  }
  .text-button-block p {
    width: auto;
  }
}
@media only screen and (min-width: 376px) and (max-width: 600px) {
  .text-button-block {
    margin: 14px 0 14px 0;
    padding: 0px;
  }
  .text-button-block button {
    display: none;
  }
  .text-button-block p {
    width: auto;
  }
}
@media (max-width: 375px) {
  .text-button-block {
    margin: 14px 0 14px 0;
    padding: 0;
  }
  .text-button-block button {
    display: none;
  }
  .text-button-block p {
    width: auto;
  }
}

.four-card-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: -3rem;
}
.four-card-block .four-card-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 242px;
  margin: 0 0 27px 0;
  padding: 42px 52.2px 36px 52.2px;
  border-radius: 30px;
  background-color: #e9f7fc;
  transition: box-shadow 0.2s;
}
.four-card-block .four-card-item:last-of-type {
  margin: 0;
}
.four-card-block .four-card-item a {
  font-size: 18px;
  text-decoration: none;
  color: #003864;
}
.four-card-block .four-card-item:hover {
  box-shadow: 0 0 0 6px #b3cee4;
  transition: box-shadow 0.2s;
}
.four-card-block h4 {
  color: #003864;
}
.four-card-block p {
  margin: 14px 0 8px;
  color: #003864;
}
@media only screen and (min-width: 376px) and (max-width: 768px) {
  .four-card-block {
    flex-direction: column;
  }
  .four-card-block .wp-block-column:nth-child(2n) {
    margin-top: 2rem;
    margin-left: 0;
  }
  .four-card-block .four-card-item {
    width: 100%;
    height: auto;
  }
  .four-card-block .four-card-item h4 {
    margin: 0;
  }
}
@media (max-width: 375px) {
  .four-card-block {
    flex-direction: column;
  }
  .four-card-block .wp-block-column:nth-child(2n) {
    margin-top: 2rem;
    margin-left: 0;
  }
  .four-card-block .four-card-item {
    width: 100%;
    height: auto;
  }
  .four-card-block .four-card-item h4 {
    margin: 0;
  }
}

.three-card-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  grid-gap: 28px;
}
.three-card-block .three-card-item {
  padding: 48px 65.4px 56px 65.4px;
  /*opacity: 0.89;*/
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #e9f7fc;
}
.three-card-block h3 {
  font-family: "Inter", sans-serif;
  font-size: 1.125rem;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #015ba6;
}
.three-card-block h6 {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.79;
  letter-spacing: normal;
  text-align: left;
  color: #333;
}
.three-card-block p {
  color: #333;
}
.three-card-block button {
  max-width: 248.9px;
  width: 100%;
  color: #fff;
  max-height: 50px;
  height: 100%;
  margin: 36px auto 0;
  padding: 13px 21.3px 14px 29.8px;
  background-color: #015ba6;
}
.three-card-block button:hover {
  box-shadow: 0 0 0 4px #ccdeed;
  transition: box-shadow 0.2s;
}
.three-card-block button:after {
  content: "";
  float: right;
  padding-right: 16px;
  background-image: url(./assets/img/link-arrow.svg);
}
.three-card-block .wp-block-column:nth-child(2n) {
  margin-left: 0 !important;
}
.three-card-block .wp-block-column:not(:first-child) {
  margin-left: 0 !important;
}
@media only screen and (min-width: 376px) and (max-width: 768px) {
  .three-card-block {
    flex-direction: column;
  }
  .three-card-block .three-card-item {
    width: 100%;
    margin: 0 0 24px 0;
  }
}
@media (max-width: 375px) {
  .three-card-block .three-card-item {
    justify-content: space-around;
    margin: 0 0 1.6875rem 0;
    padding: 32px 28.7px 34px 28.7px;
  }
  .three-card-block button {
    margin: 2.25rem 0 0 0;
    width: 100%;
  }
}

.double-text-block {
  display: flex;
  justify-content: space-between;
  margin: 16px 0;
  flex-wrap: wrap;
}
.double-text-block h3 {
  font-family: "Inter", sans-serif;
  font-size: 30px !important;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #015ba6;
}
.double-text-block p {
  color: #003864;
}
.double-text-block .double-text-block-rectangle {
  width: 108.3px;
  height: 11px;
  margin: 0 476.6px 55px 0px;
  border-radius: 40px;
  background-color: #e9f7fc;
}
.double-text-block .double-text-block-item {
  max-width: 641px;
  width: 100%;
  padding: 49px 87px 40px 56px;
}
.double-text-block h4 {
  margin: 0 0 32px 4.9px;
  color: #015ba6;
}
.double-text-block span {
  font-weight: 600;
  color: #333;
}
.double-text-block .double-text-card-item {
  max-width: 641px;
  width: 100%;
  height: 310px;
  margin: 24px 0 0 0;
  padding: 49px 56px 40px 56px;
  opacity: 0.89;
  border-radius: 30px;
  background-color: #e9f7fc;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.double-text-block .double-text-card-item a {
  font-size: 18px;
  color: #003864;
}
@media only screen and (min-width: 376px) and (max-width: 1440px) {
  .double-text-block {
    justify-content: space-around;
  }
  .double-text-block .double-text-block-item {
    width: 100%;
  }
  .double-text-block .double-text-card-item {
    width: 100%;
  }
  .double-text-block #item-1 {
    order: 1 !important;
  }
  .double-text-block #item-2 {
    order: 3 !important;
  }
  .double-text-block #item-3 {
    order: 2 !important;
  }
  .double-text-block #item-4 {
    order: 4 !important;
  }
}
@media (max-width: 375px) {
  .double-text-block .double-text-block-item {
    width: 100%;
    margin: 24px 0 24px 11px;
    height: 100%;
    padding: 0;
  }
  .double-text-block .double-text-block-item p {
    width: 100%;
  }
  .double-text-block .double-text-card-item {
    width: 100%;
  }
  .double-text-block .double-text-card-item p {
    width: 100%;
  }
  .double-text-block #item-1 {
    order: 1 !important;
  }
  .double-text-block #item-2 {
    order: 3 !important;
  }
  .double-text-block #item-3 {
    order: 2 !important;
  }
  .double-text-block #item-4 {
    order: 4 !important;
  }
  .double-text-block h4 {
    width: 100%;
    height: auto;
  }
  .double-text-block button {
    margin: 2.25rem 0 0 0;
    width: 100%;
  }
}

.paragraph-card-blue, p.paragraph-card-blue {
  max-width: 572px;
  width: 100%;
  padding: 33px 59px 33px 59px;
  opacity: 0.9;
  border-radius: 30px;
  background-color: #e9f7fc;
  margin-top: 48px;
}
@media screen and (max-width: 768px) {
  .paragraph-card-blue, p.paragraph-card-blue {
    max-width: initial;
  }
}

.paragraph-card-clear {
  max-width: 572px;
  width: 100%;
  padding: 33px 59px 33px 59px;
}
@media screen and (max-width: 768px) {
  .paragraph-card-clear {
    padding: 48px 0 0 0;
    max-width: initial;
  }
}

.media-right {
  text-align: center;
  color: #015ba6 !important;
}
.media-right .wp-block-embed__wrapper {
  box-shadow: 0 5.3px 5.3px 0 rgba(0, 0, 0, 0.15);
  margin: 10px 0 0 0;
  max-width: 440px;
  width: 100%;
  max-height: 255px;
  float: right;
  position: relative;
  top: -100px;
  left: -45px;
}
@media (max-width: 1350px) {
  .media-right {
    position: initial;
    float: right;
    padding: 0 40px;
    margin: 3rem 0 !important;
  }
}
@media (max-width: 375px) {
  .media-right {
    padding: 0;
  }
}

.side-menu-list {
  max-width: 275px;
  width: 100%;
  float: right;
  padding-bottom: 24px;
}
.side-menu-list ul {
  padding: 0;
  margin: 0;
}
.side-menu-list ul ul {
  border-bottom: 2px solid #e9f7fc;
  padding-bottom: 20px !important;
}
.side-menu-list span {
  color: #003864 !important;
}
.side-menu-list ul > ul > span {
  padding: 20px 0;
  opacity: 0.9;
}
.side-menu-list a {
  color: #015ba6 !important;
  padding: 20px 0;
  border-bottom: 2px solid #e9f7fc;
}
.side-menu-list ul > ul > a {
  border-left: 1px solid #015ba6;
  margin-left: 16px !important;
  padding-left: 16px !important;
  border-bottom: none;
}
@media (max-width: 1024px) {
  .side-menu-list {
    float: none;
  }
}
.side-menu-list a:hover {
  text-decoration: underline !important;
}

.double-text-card {
  display: flex;
  justify-content: space-between;
  margin: 16px 0;
  flex-wrap: wrap;
}
.double-text-card h3 {
  font-family: "Inter", sans-serif;
  font-size: 30px !important;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #015ba6;
}
.double-text-card p {
  color: #003864;
}
.double-text-card .double-text-block-rectangle {
  width: 108.3px;
  height: 11px;
  margin: 0 476.6px 55px 0px;
  border-radius: 40px;
  background-color: #e9f7fc;
}
.double-text-card .double-text-block-item {
  max-width: 641px;
  width: 100%;
  padding: 49px 87px 40px 56px;
}
.double-text-card h4 {
  margin: 0 0 32px 4.9px;
  color: #015ba6;
}
.double-text-card span {
  font-weight: 600;
  color: #333;
}
.double-text-card li:hover {
  box-shadow: 0 0 0 6px #b3cee4;
  transition: box-shadow 0.2s;
}
.double-text-card .double-text-card-item, .double-text-card li {
  max-width: 641px;
  width: 100%;
  margin: 24px 0 0 0;
  padding: 49px 56px 40px 56px;
  opacity: 0.89;
  border-radius: 30px;
  background-color: #e9f7fc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: box-shadow 0.2s;
}
.double-text-card .double-text-card-item a, .double-text-card li a {
  font-size: 18px;
  color: #003864 !important;
  text-decoration: none;
}
@media only screen and (min-width: 376px) and (max-width: 1440px) {
  .double-text-card {
    justify-content: space-around;
  }
  .double-text-card .double-text-block-item {
    width: 100%;
  }
  .double-text-card .double-text-card-item, .double-text-card li {
    width: 100%;
  }
  .double-text-card #item-1 {
    order: 1 !important;
  }
  .double-text-card #item-2 {
    order: 3 !important;
  }
  .double-text-card #item-3 {
    order: 2 !important;
  }
  .double-text-card #item-4 {
    order: 4 !important;
  }
}
@media (max-width: 375px) {
  .double-text-card .double-text-block-item {
    width: 100%;
    margin: 24px 0 24px 11px;
    height: 100%;
    padding: 0;
  }
  .double-text-card .double-text-block-item p {
    width: 100%;
  }
  .double-text-card .double-text-card-item, .double-text-card li {
    width: 100%;
  }
  .double-text-card .double-text-card-item p, .double-text-card li p {
    width: 100%;
  }
  .double-text-card #item-1 {
    order: 1 !important;
  }
  .double-text-card #item-2 {
    order: 3 !important;
  }
  .double-text-card #item-3 {
    order: 2 !important;
  }
  .double-text-card #item-4 {
    order: 4 !important;
  }
  .double-text-card h4 {
    width: 100%;
    height: auto;
  }
  .double-text-card button {
    margin: 2.25rem 0 0 0;
    width: 100%;
  }
}

.menu-list-card {
  display: flex;
  flex-direction: column;
  width: fit-content;
  padding: 33px 59px 33px 59px;
  opacity: 0.9;
  border-radius: 30px;
  background-color: #e9f7fc;
  margin-top: 48px;
}
.menu-list-card a {
  margin: 0;
  padding: 8px 0;
  color: #003864 !important;
}
.menu-list-card a:hover {
  text-decoration: underline;
}
.menu-list-card ul {
  padding: 0;
  margin: 0;
}
.menu-list-card span {
  color: #003864 !important;
  padding: 20px 0;
}
.menu-list-card ul > a {
  border-left: 1px solid #015ba6;
  margin: 0 0 0 16px !important;
  padding-left: 16px !important;
  border-bottom: none;
}
@media screen and (max-width: 768px) {
  .menu-list-card {
    width: 100%;
  }
}

.download-block {
  padding: 48px 65.4px 56px 65.4px;
  opacity: 0.89;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #e9f7fc;
}
.download-block h3 {
  font-family: "Inter", sans-serif;
  font-size: 18px !important;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #015ba6;
  margin-bottom: 16px;
}
.download-block #download-item {
  margin: 24px 0;
  padding: 24px 0;
  flex-direction: row;
}
.download-block #download-item:not(:first-of-type) {
  border-top: 2px solid #c6dfef;
}
@media screen and (max-width: 768px) {
  .download-block #download-item {
    border-top: 2px solid #c6dfef;
    flex-direction: column;
  }
  .download-block #download-item button {
    margin-top: 16px;
  }
}
.download-block p {
  color: #333;
  margin: 0;
}
.download-block button {
  max-width: 248.9px;
  width: 100%;
  color: #fff;
  max-height: 50px;
  height: 100%;
  padding: 13px 21.3px 14px 29.8px;
  background-color: #015ba6;
}

.download-block-wide {
  padding: 48px 0 0 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.download-block-wide .text-button-block-rectangle {
  width: 110px;
  height: 11px;
  margin: 70px 0 55px 0;
  border-radius: 40px;
  background-color: #e9f7fc;
}
.download-block-wide h3 {
  color: #015ba6;
  margin-bottom: 16px;
}
.download-block-wide #download-item {
  margin: 24px 0;
  padding: 24px 0;
}
.download-block-wide p {
  color: #333;
  margin: 0;
}
.download-block-wide button {
  max-width: 248.9px;
  color: #fff;
  max-height: 50px;
  height: 100%;
  padding: 13px 21.3px 14px 29.8px;
  background-color: #015ba6;
}
.download-block-wide button:hover {
  background-color: #e9f7fc;
  color: #015ba6;
}

.item-grid {
  padding-bottom: 48px;
  padding-left: 52px;
}
.item-grid h4 {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #003864 !important;
}
.item-grid .col-item {
  background-color: #e9f7fc;
  color: #015ba6;
  border-radius: 30px;
  width: 100%;
  text-align: center;
  padding: 10px 24px;
  margin: 8px 0 0 0;
  white-space: nowrap;
  transition: 0.3s 0s ease-in-out;
}
.item-grid .col-item:hover {
  background-color: #015ba6;
  color: #e9f7fc;
}
.item-grid .col {
  flex-grow: 1;
  padding: 0 8px 0 0 !important;
}
@media screen and (max-width: 768px) {
  .item-grid {
    padding-left: 0;
  }
  .item-grid .row {
    flex-direction: column;
    margin: 0;
  }
}

.text-list-block {
  padding: 38px 77px 28px 103px;
  border-radius: 30px;
  background-color: #e9f7fc;
  margin: 14px 0;
  justify-items: end;
}
.text-list-block ul {
  padding: 0;
  margin: 0;
}
.text-list-block a, .text-list-block li {
  margin: 0;
  padding: 8px 0;
  font-family: "inter", sans-serif;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #015ba6 !important;
  list-style-type: none;
}
.text-list-block a:not(:last-child), .text-list-block li:not(:last-child) {
  border-bottom: 2px solid white;
  max-width: 250px;
  width: 100%;
}
.text-list-block a:hover, .text-list-block li:hover {
  text-decoration: underline;
}
.text-list-block .has-large-font-size {
  margin: 62px 0 62px 0;
  font-family: "Inter", sans-serif;
  font-size: 30px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #015ba6;
}

.col-block .text-button-block-rectangle {
  width: 110px;
  height: 11px;
  margin: 70px 0 55px 0;
  border-radius: 40px;
  background-color: #e9f7fc;
}
.col-block p {
  font-size: 14px;
  color: #003864;
}
.col-block h4, .col-block h2 {
  font-weight: 900;
}
.col-block a {
  color: #015ba6;
  font-weight: bold;
  text-decoration: underline;
  transition: color ease 0.3s;
}

.news-and-post-grid article {
  padding: 48px 26.4px 56px 65.4px;
  opacity: 0.89;
  border-radius: 30px;
  background-color: #e9f7fc;
}
.news-and-post-grid article header {
  background-color: transparent;
}
.news-and-post-grid article header h3.gb-block-post-grid-title a {
  font-size: 18px !important;
  font-weight: 800;
  color: #015ba6 !important;
}
.news-and-post-grid article header time {
  font-weight: 600;
  line-height: 1.79;
  color: #333;
}
@media screen and (max-width: 375px) {
  .news-and-post-grid article {
    padding: 48px;
  }
}

.recent-updates-block .text-button-block-rectangle {
  width: 110px;
  height: 11px;
  margin: 70px 0 55px 0;
  border-radius: 40px;
  background-color: #e9f7fc;
}
.recent-updates-block h2 {
  font-size: 30px;
  font-weight: 900;
  line-height: 1.43;
  color: #015ba6;
}
.recent-updates-block ul {
  padding: 0;
}
.recent-updates-block .featuredpost article {
  margin-right: 40px;
}
.recent-updates-block .featuredpost article .gb-block-post-grid-text {
  padding: 40px 0;
  opacity: 1;
  border-radius: 0px;
  background-color: transparent;
}
.recent-updates-block .featuredpost article .gb-block-post-grid-text a {
  font-size: 18px;
  color: #015ba6;
}
.recent-updates-block .featuredpost article .gb-block-post-grid-text .gb-text-link {
  padding: 14px 10px 13px 26px;
  border-radius: 30px;
  background-color: #015ba6;
  color: white;
  width: 40%;
  font-size: 14px;
}
.recent-updates-block .featuredpost article .gb-block-post-grid-text .gb-text-link:after {
  content: "";
  height: 24px;
  width: 60px;
  background-image: url(./assets/img/icons/arrow-down-solid.svg);
}
.recent-updates-block .second .gb-block-layout-column-inner ul {
  padding: 30px;
  border-radius: 30px;
  background-color: #e9f7fc;
}
.recent-updates-block .second .gb-block-layout-column-inner ul a {
  color: #015ba6;
}

.webinar-block h2 {
  font-size: 30px;
  font-weight: 900;
  line-height: 1.43;
  color: #015ba6;
}
.webinar-block h4 {
  font-size: 18px;
  font-weight: 800;
  line-height: 1.67;
  color: #015ba6;
}
.webinar-block .text-button-block-rectangle {
  width: 110px;
  height: 11px;
  margin: 70px 0 55px 0;
  border-radius: 40px;
  background-color: #e9f7fc;
}
.webinar-block .featuredpost article .gb-block-post-grid-text {
  padding: 41px 43px 40px 37px;
  opacity: 0.89;
  border-radius: 30px;
  background-color: #e9f7fc;
}
.webinar-block .featuredpost article .gb-block-post-grid-text a {
  font-size: 18px;
  font-weight: 800;
  line-height: 1.67;
  color: #015ba6;
}

.glossary-block h2 {
  font-size: 30px;
  font-weight: 900;
  line-height: 1.43;
  color: #015ba6;
}
.glossary-block h4 {
  font-size: 18px;
  font-weight: 800;
  line-height: 1.67;
  color: #015ba6;
}
.glossary-block .text-button-block-rectangle {
  width: 110px;
  height: 11px;
  margin: 70px 0 55px 0;
  border-radius: 40px;
  background-color: #e9f7fc;
}
.glossary-block .wp-block-genesis-blocks-gb-columns .gb-is-responsive-column .gb-block-layout-column {
  padding: 34px 169.2px 39px 33px;
  border-radius: 20px;
  background-color: #e9f7fc;
}
.glossary-block .wp-block-genesis-blocks-gb-columns .gb-is-responsive-column .gb-block-layout-column a {
  background-color: transparent;
  color: #015ba6;
  text-decoration: underline;
}
.glossary-block .wp-block-genesis-blocks-gb-columns .gb-is-responsive-column .gb-block-layout-column h5 {
  font-size: 16px;
  font-weight: bold;
  line-height: normal;
  color: #015ba6;
}
.glossary-block .wp-block-genesis-blocks-gb-columns .gb-is-responsive-column .gb-block-layout-column div {
  margin: 0;
}

.nav-block {
  max-width: 275px;
  width: 100%;
  float: right;
  padding-bottom: 24px;
}
.nav-block ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.nav-block ul li {
  border-bottom: 2px solid #e9f7fc;
  padding: 20px 0 !important;
}
.nav-block ul li:first-child {
  padding: 0;
}
.nav-block span, .nav-block p {
  color: #003864 !important;
}
.nav-block ul > li span, .nav-block ul > li p {
  padding: 20px 0;
  opacity: 0.6;
}
.nav-block a {
  color: #015ba6 !important;
  padding: 20px 0;
}
.nav-block ul > li > ul > li {
  border-left: 1px solid #015ba6;
  margin-left: 16px !important;
  padding-left: 16px !important;
  border-bottom: none;
}
@media (max-width: 1024px) {
  .nav-block {
    float: none;
  }
}

.r-w-25 {
  width: 25%;
}
@media (max-width: 1024px) {
  .r-w-25 {
    width: 100%;
  }
}

.r-w-50 {
  width: 50%;
}
@media (max-width: 1024px) {
  .r-w-50 {
    width: 100%;
  }
}

.r-w-75 {
  width: 75%;
}
@media (max-width: 1024px) {
  .r-w-75 {
    width: 100%;
  }
}

/*  Anupam khosla changes from 22-04-2022 
    I will override previous styles or delete the older ones
    For IE11 to work wordpress generated  inline styles(grid system)
    has to be swapped with flex layouts  
*/
/*global styles start here; no componenets*/
html, body {
  font-size: 1rem;
}

h1, h2, h3, h4, h5, h6 {
  color: #015ba6;
}

h1 {
  font-size: 2.25rem;
}

h2 {
  font-size: 30px;
  font-weight: 900;
  margin-bottom: 21px;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 14px;
}

p {
  font-family: "Inter", sans-serif;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: #003864;
}

a {
  overflow-wrap: break-word;
  color: #015ba6;
}

a:not[href] {
  cursor: initial;
}

ul {
  font-size: 14px;
  padding-left: 0;
}

ol {
  font-size: 14px;
}

button[type=submit] {
  padding: 12px 30px;
  background-color: #015ba6;
  width: auto;
  min-width: 200px;
  border-radius: 30px;
  color: #fff;
  text-align: center;
  min-height: 0;
  margin-top: 20px;
}

img {
  height: auto;
}

table {
  margin-bottom: 1rem;
}

#app .container div.row {
  flex-wrap: wrap !important;
  /*overriding iToc app.js*/
}

.page {
  display: flex;
  flex-direction: column;
}

.entry-content {
  flex-direction: column;
  display: flex;
}
.entry-content h1 {
  margin-top: 40px;
}

.page-container .container {
  flex: initial;
  max-width: 1260px;
  padding: 25px 15px 30px;
}

main:not(.inner) > .container {
  padding: 65px 0 20px 0;
}

.page-container .components {
  padding: 50px 0;
}

#content.inner .container > article {
  margin: auto;
  max-width: 900px;
  padding: 25px 0 0 0;
}

#content .container > article#post-2572,
#content .container > article#post-2718 {
  max-width: 1260px;
}

.inner .container {
  max-width: 900px;
}
.inner .custom-select {
  margin-bottom: 50px;
}
.inner .gb-block-layout-column-inner h3 {
  font-size: 18px;
  font-weight: 700;
  color: #003864;
}

.text-blue {
  color: #015ba6;
}

.text-dark-blue {
  color: #003864;
}

/*global styles finish here*/
/*Bootstrap and wordpress overrides*/
@media (min-width: 768px) {
  .offset-md-half {
    margin-left: 4.16666%;
  }
}
.container .row {
  margin-bottom: 0;
}

.d-flex2 {
  display: flex;
}

.f-w-600 {
  font-weight: 600;
}

.navbar-nav .nav-link {
  padding: 8px;
}

a.nav-link:focus, a.nav-link:hover {
  text-decoration: underline;
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: #fff;
}

.navbar-toggler {
  padding: 0;
  margin-left: 15px;
  border-radius: 25px;
}

.navbar-light .navbar-toggler-icon {
  background-image: none;
  width: auto;
  height: auto;
}

.dropdown-menu {
  margin: 0;
}

.collapsing {
  transition: height 0.15s linear;
}

.modal-content {
  border: 0;
  border-radius: 0;
}

.modal-header {
  padding: 45px 65px;
  border-bottom: 0;
}

.modal-body {
  padding: 0 65px 85px;
}

.modal-header {
  align-items: center;
}
.modal-header .close {
  padding: 0;
  margin: 0;
}

.close:not(:disabled):not(.disabled):focus, .close:not(:disabled):not(.disabled):hover {
  opacity: 1;
  color: #015ba6;
  text-decoration: underline;
}

.close {
  font-size: 14px;
  text-decoration: underline;
  color: #015ba6;
  opacity: 1;
}

.form-group {
  margin-bottom: 24px;
}

.form-control {
  border-radius: 12px;
}

.form-control:focus {
  color: #015ba6;
}

.form-check-input {
  margin: 0;
  position: static;
}

.dropdown-item:focus, .dropdown-item:hover {
  background-color: transparent;
}

.wp-block-button__link {
  font-size: 14px;
}

.wp-block-image:not(.is-style-rounded) img {
  border-radius: 0;
}

/*overrides finish*/
.ie .gb-layout-column-wrap {
  display: flex;
}

.components button {
  border: 0;
  font-size: 14px;
}
.components .wpcf7-form {
  /* input, */
}
.components .wpcf7-form label {
  color: #015ba6;
  font-size: 14px;
  margin-bottom: 12px;
}
.components .wpcf7-form select {
  border: 1px solid #BCC0C3;
  border-radius: 12px;
  min-height: 60px;
  color: #015ba6;
  font-size: 14px;
  font-weight: 500;
}
.components .wpcf7-form textarea {
  border: 1px solid #BCC0C3;
  border-radius: 12px;
  width: 100%;
  padding-left: 20px;
}
.components .non-vuejs label {
  color: #015ba6;
  font-size: 14px;
  margin-bottom: 12px;
}
.components .non-vuejs input, .components .non-vuejs select {
  border: 1px solid #BCC0C3;
  border-radius: 12px;
  min-height: 60px;
  color: #015ba6;
  font-size: 14px;
  font-weight: 500;
}
.components .non-vuejs textarea {
  border: 1px solid #BCC0C3;
  border-radius: 12px;
  width: 100%;
  padding-left: 20px;
}
.components .non-vuejs button[type=submit] {
  padding: 20px 30px;
}
.components p.desc {
  font-size: 20px;
  line-height: 34px;
  color: #003864;
  margin-bottom: 50px;
}
.components .nav-list {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}
.components .nav-list li {
  font-weight: 600;
  margin-bottom: 9px;
}
.components .nav-list li:last-child {
  margin-bottom: 0;
}
.components .nav-list a {
  text-decoration: none;
  color: #015ba6;
}
.components .oxide-card {
  background-color: #e9f7fc;
  padding: 35px 35px 35px 50px;
  border-radius: 30px;
  margin-bottom: 20px;
}
.components .oxide-card + hr.wp-block-separator.is-style-wide:not(.thin) {
  margin-top: 60px;
}
.components .oxide-card p {
  color: #003864;
  margin-bottom: 8px;
}
.components .oxide-card .gb-block-layout-column-inner {
  position: static;
}
.components .oxide-card .gb-container-content > p:last-of-type {
  margin-bottom: 30px;
}
.components .oxide-card hr.wp-block-separator.thin.is-style-wide {
  margin: 24px 0 22px 0;
}
.components .oxide-card .date {
  font-weight: 600;
  color: #333;
}
.components .pill-download {
  display: block;
  margin-bottom: 15px;
}
.components .pill-download:last-child {
  margin-bottom: 0;
}
.components .pill-download .gb-block-layout-column-gap-2 {
  display: flex;
  grid-gap: 0 10px;
  align-items: center;
}
.components .pill-download .gb-block-layout-column {
  width: auto;
}
.components .pill-download .wp-block-column {
  flex: 0 0 auto;
}
.components .pill-download a {
  color: #003864;
}
.components .pill-download h3 {
  margin-bottom: 0;
}
.components .pill-download p {
  font-weight: 600;
  color: #015ba6;
  margin-bottom: 0;
}
.components .pill-download .wp-block-button__link {
  display: inline-flex;
  line-height: 24px;
  color: #003864;
  background-color: #e9f7fc;
  padding: 3px 16px;
  font-size: 14px;
  border-radius: 8px;
}
.components .pill-download .wp-block-button {
  margin-bottom: 0;
}
.components article .pill-img {
  padding: 21px 33px;
  border-radius: 30px;
  background-color: #E9EEF1;
  margin-bottom: 30px;
}
.components article .pill-img figure {
  margin: 0;
  width: 83px;
  height: 83px;
  background-color: transparent;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.components article .pill-img img {
  border-radius: unset;
}
.components article .pill-img h3 {
  margin-bottom: 14px;
}
.components article .pill-img p {
  font-size: 18px;
  line-height: 28px;
}
.components article .pill-img .gb-layout-column-wrap {
  grid-template-columns: 83px 1fr;
  grid-gap: 36px;
}
.components article .pill-img .gb-block-layout-column {
  display: inline-grid;
  align-content: center;
}
.components article .pill-yellow {
  background-color: #FEEDDA;
}
.components article .pill-blue {
  background-color: #EDEAF3;
}
.components article .pill-cyan {
  background-color: #E3F4F5;
}
.components article .pill-green {
  background-color: #EAF6F1;
}
.components article .pill-pink {
  background-color: #FADEE2;
}
.components .custom-select {
  border-radius: 12px;
  border: 1px solid #BCC0C3;
  width: 350px;
  height: 60px;
  font-size: 14px;
  font-weight: 500;
  padding-left: 20px;
  color: #015ba6;
  background: url("../icons/select_down.png") no-repeat right 25px center;
  text-align: left;
}
.components .pagination-basic {
  color: #015ba6;
  font-weight: 500;
  margin-bottom: 20px;
}
.components .pagination-basic a {
  margin-left: 70px;
  text-decoration: underline;
}
.components .three-card-item {
  justify-content: flex-start;
}
.components .three-card-item h3 {
  margin-bottom: 15px;
}
.components .filter-category {
  margin-bottom: 10px;
}
.components .dynamic-page-count {
  color: #015ba6;
  font-weight: 600;
}
.components .no-res-msg {
  margin-top: 30px;
}
.components .pagination {
  margin-top: 28px;
  padding: 0 15px;
  width: 100%;
}
.components .pagination p {
  margin: 0;
}
.components .pagination .gb-is-responsive-column {
  display: flex;
}
.components .pagination .gb-block-layout-column {
  display: flex;
  align-items: center;
  width: auto;
}
.components .pagination .gb-block-layout-column-gap-2 {
  grid-gap: 0 60px;
}
.components .pagination .gb-block-layout-column-gap-2 .gb-block-layout-column-gap-2 {
  grid-gap: 0 20px;
}
.components .pagination .gb-layout-columns-2 > .gb-layout-column-wrap {
  grid-template-columns: none;
}
.components .pagination .wp-block-buttons {
  column-gap: 15px;
}
.components .pagination .wp-block-button {
  margin-bottom: 0;
}
.components .pagination .wp-block-button__link:active,
.components .pagination .wp-block-button__link:focus,
.components .pagination .wp-block-button__link:hover,
.components .pagination .wp-block-button__link:visited {
  color: #fff;
}
.components .pagination .wp-block-button__link {
  font-size: 14px;
  padding: 16px 30px 17px 20px;
  background-color: #015ba6;
  color: #fff;
}
.components .pagination .wp-block-button__link img {
  position: relative;
  top: -1px;
  margin: 0 10px 0 0;
  transform: scale(-1);
  /*inverting arrow*/
}
.components .pagination .wp-block-button:nth-child(2) .wp-block-button__link {
  color: #fff;
  padding: 16px 33px 17px 43px;
}
.components .pagination .wp-block-button:nth-child(2) .wp-block-button__link img {
  margin: 0 0px 0 10px;
  transform: scale(1);
}
.components .pagination .wp-block-button.disable-if-empty a:not([href]) {
  color: #B0B0B0;
  background-color: #f2f2f2;
}
.components .pagination .wp-block-button.disable-if-empty a:not([href]) img {
  filter: brightness(0.7);
}
.components .oxide-pagination {
  width: auto;
  padding-right: 49px;
  margin-bottom: 0;
  display: none;
}
.components > div[class*=wp-block-] {
  margin-bottom: 100px;
}
.components .link .wp-block-button__link {
  font-size: 14px;
  font-weight: 700;
  text-decoration: underline;
}
.components .wp-block-button__link:active,
.components .wp-block-button__link:focus,
.components .wp-block-button__link:hover,
.components .wp-block-button__link:visited {
  color: #015ba6;
}
.components .wp-block-button__link {
  background-color: transparent;
  padding: 0;
  display: inline;
}
.components .wp-block-embed {
  margin-bottom: 0;
}
.components .wp-block-separator:not(.thin) {
  width: 110px;
  height: 11px;
  border-radius: 40px;
  background-color: #e9f7fc;
  border-style: none;
  opacity: 1;
  margin: 90px 0 50px 0;
}
.components .d-flex p + .wp-block-separator:not(.thin) {
  margin: 74px 0 50px 0;
}
.components hr.wp-block-separator.thin.is-style-wide {
  width: 100%;
  border-bottom-color: #D6D6D6;
  opacity: 1;
  margin: 16px 0 32px;
}
.components .uct_cards + .wp-block-separator:not(.thin) {
  margin: 70px 0 50px 0;
}
.components .gb-block-layout-column-gap-2 {
  grid-gap: 0 30px;
}
.components .gb-2-col-wideright > .gb-layout-column-wrap {
  grid-template-columns: 26fr 60fr;
}

.btn-pm .wp-block-button__link {
  background-color: #015ba6;
  padding: 14px 19px 14px 25px;
  color: white;
  display: block;
}
.btn-pm .wp-block-button__link:active,
.btn-pm .wp-block-button__link:focus,
.btn-pm .wp-block-button__link:hover,
.btn-pm .wp-block-button__link:visited {
  color: white;
}

.about-ul {
  color: #003864;
}

.wp-block-embed-youtube figcaption a {
  color: #015ba6;
  font-weight: 700;
  text-decoration: underline;
}

.wp-block-embed figcaption {
  margin-top: 1rem;
}

.link-underlined a {
  font-weight: 700;
  text-decoration: underline;
}

.long-pill-columns {
  margin-bottom: 34px;
}
.long-pill-columns .gb-block-layout-column-gap-2 {
  grid-template-columns: 900fr 290fr;
}
.long-pill-columns .gb-block-layout-column:nth-child(2) {
  padding-left: 40px;
}

.pill-lg-btn {
  width: 100%;
}
.pill-lg-btn .wp-block-button__link {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 30px;
  background-color: #e9f7fc;
  font-weight: 600;
  padding: 25px 30px 25px 40px;
  border-radius: 30px;
}
.pill-long {
  background-color: #e9f7fc;
  padding: 20px 30px;
  margin-bottom: 30px;
  border-radius: 30px;
}
.pill-long > .gb-block-layout-column-gap-2 {
  grid-gap: 30px;
}
.pill-long.gb-layout-columns-2 > .gb-layout-column-wrap {
  grid-template-columns: 76fr 24fr;
  align-items: center;
}
.pill-long p {
  position: relative;
  margin: 0 0 0 80px;
  display: flex;
  align-items: center;
  font-size: 20px;
  min-height: 46px;
}
.pill-long p img {
  left: -55px;
  position: absolute;
  transform: translateX(-50%);
}
.pill-long p::before {
  content: "";
  position: absolute;
  width: 50px;
  height: 50px;
  background-color: #90C547;
  z-index: -1;
  border-radius: 50%;
  left: -80px;
}
.pill-long:nth-of-type(odd) p::before {
  background-color: #015ba6;
}
.pill-long .wp-block-button {
  margin-bottom: 0;
}

.check-list img {
  align-self: flex-start;
  margin: 4px 20px 0 0;
}
.check-list li {
  display: flex;
  color: #003864;
  font-size: 18px;
  align-items: flex-start;
  margin-bottom: 7px;
}

.border-list {
  list-style-type: none;
  margin-bottom: 83px;
  padding-left: 0;
  counter-reset: section;
  /* flex box list item bug: https://stackoverflow.com/q/25942417/3429430 */
}
.border-list li {
  min-height: 60px;
  border-bottom: 1px solid #D6D6D6;
  color: #003864;
  padding: 10px 0 10px 40px;
  display: flex;
  align-items: center;
}
.border-list li:before {
  counter-increment: section;
  content: counter(section) "";
  position: absolute;
  margin-left: -40px;
}

.wp-block-table table {
  font-size: 14px;
  border-bottom: none;
}
.wp-block-table table tr:not(:first-child) td {
  vertical-align: middle;
}
.wp-block-table table td {
  color: #003864;
  padding: 6px 0 0 0;
  /*gutenberg editor puts inline css with important. 
  Other solution is to dequeue that in functions.php*/
  height: 60px;
  border-bottom: 1px solid #D6D6D6;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  line-height: 25px;
}

.wp-block-table thead {
  border: 0;
}
.wp-block-table thead th {
  color: #003864;
  font-weight: 700;
  border: none;
  height: 60px;
  padding: 0;
}

.table-grand-container {
  overflow-x: auto;
}
.table-grand-container.two-col .table-container {
  min-width: 690px;
}
.table-grand-container .two-col .wp-block-column:first-child th {
  text-align: left;
  vertical-align: top;
}
.table-grand-container .two-col th {
  text-align: center;
  vertical-align: top;
}

.table-container {
  min-width: 870px;
  display: grid;
  column-gap: 20px;
  grid-template-columns: 200fr 320fr 320fr;
  margin-bottom: 20px;
  transition: opacity 0.3s ease;
}
.table-container.two-col {
  grid-template-columns: 2fr 1fr;
}
.table-container .title2 {
  margin-bottom: 0;
}
.table-container .title2 td {
  border-bottom: 0;
  font-weight: 700;
  color: #015ba6;
}
.table-container .wp-block-column:not(:first-child) {
  margin-left: 0;
}
.table-container .is-style-stripes tbody tr:nth-child(odd) {
  background-color: transparent;
}
.table-container .inner td {
  width: 100px;
  text-align: center;
  color: #015ba6;
}
.table-container figure:nth-child(2) tr:first-child td {
  position: relative;
  color: #015ba6;
}
.table-container figure:nth-child(2) tr:first-child td:before {
  top: 10px;
  content: "▲";
  position: absolute;
  display: block;
  opacity: 0.25;
  right: 0px;
  line-height: 9px;
  font-size: 0.8em;
  z-index: 2;
}
.table-container figure:nth-child(2) tr:first-child td:after {
  content: "▼";
  position: absolute;
  display: block;
  opacity: 0.25;
  top: 19px;
  right: 0px;
  line-height: 9px;
  font-size: 0.8em;
  z-index: 2;
}
.table-container figure:nth-child(2) tr:first-child td.asc:before {
  opacity: 0.6;
}
.table-container figure:nth-child(2) tr:first-child td.desc:after {
  opacity: 0.6;
}
.table-container .wp-block-column:not(:nth-child(1)) figure:nth-child(2) tr:first-child td:nth-child(1):before,
.table-container .wp-block-column:not(:nth-child(1)) figure:nth-child(2) tr:first-child td:nth-child(1):after {
  right: -3px;
}
.table-container figure:nth-child(2) tr:first-child td:nth-child(2) {
  left: 5px;
}
.table-container figure:nth-child(2) tr:first-child td:nth-child(2):before,
.table-container figure:nth-child(2) tr:first-child td:nth-child(2):after {
  right: -10px;
}

.ref, .ref-multi {
  /*IMPORTANT Assuming dynamic content in these two tables, it is not possible to ensure border-bottoms match for each line.
  Easisest solution is to use ul>li and then reorder even li's at the bottom with flex-order  
  This improvement can me made in later versions of the project*/
}
.ref > table, .ref-multi > table {
  margin-bottom: 16px;
  min-width: 690px;
}
.ref.wp-block-table table tr:not(:first-child) td, .ref.wp-block-table tr td, .ref-multi.wp-block-table table tr:not(:first-child) td, .ref-multi.wp-block-table tr td {
  height: auto;
  vertical-align: top;
  padding-top: 10px !important;
  /*overriding gutenberg inline styles*/
  padding-bottom: 10px !important;
  width: 50%;
}
.ref th:first-child, .ref td:first-child, .ref-multi th:first-child, .ref-multi td:first-child {
  padding-right: 30px;
}
.ref th:last-child, .ref td:last-child, .ref-multi th:last-child, .ref-multi td:last-child {
  padding-left: 30px;
}

.ref-multi.wp-block-table table tr:not(:first-child) td, .ref-multi.wp-block-table tr td {
  width: auto;
}
.ref-multi.wp-block-table th, .ref-multi.wp-block-table td {
  padding-right: 30px;
}
.ref-multi th:last-child, .ref-multi td:last-child {
  padding-left: 0;
}

.caption-img {
  margin-top: 90px;
  display: flex;
  flex-direction: column;
  row-gap: 38px;
  align-items: center;
  background-color: #f9f9f9;
  padding-top: 38px;
  border-radius: 30px;
  overflow: hidden;
}
.caption-img img {
  padding: 0 10px;
}
.caption-img figcaption {
  width: 100%;
  background-color: #003864;
  margin: 0;
  padding: 40px 67px 44px;
  font-size: 14px;
  color: #fff;
}

.banner_image.reverse {
  flex-direction: row-reverse;
}
.banner_image.reverse .wp-block-media-text__content {
  padding: 50px 0 0 0;
}
.banner_image.reverse .wp-block-media-text__content p {
  color: #003864;
  font-size: 1.25rem;
}
.banner_image.reverse .wp-block-media-text__media {
  padding: 0 0 0 122px;
}
.banner_image.reverse .wp-block-media-text__media::after {
  left: 86px;
}

.wp-block-image.regular figcaption {
  text-align: center;
  color: #003864;
  font-size: 14px;
  margin: 10px 0 0 17px;
}

.snomed-heading {
  margin-bottom: 50px;
}

.uct_cards .three-card-item {
  /* fontawesome arrow icon
  .wp-block-button:before {
    content: "\f061"
  }
  */
}
.uct_cards .three-card-item {
  word-break: normal;
  padding: 32px 60px 75px 40px;
  position: relative;
  justify-content: flex-start;
}
.uct_cards .three-card-item p {
  margin: 0;
}
.uct_cards .three-card-item .wp-block-button {
  display: flex;
  color: #015ba6;
  position: absolute;
  bottom: 29px;
  /*6px extra from figma*/
  right: 34px;
  /*1px extra from figma*/
  margin-bottom: 0;
}

.uct_two_block {
  margin: -20px 0 0px 0;
}
.uct_two_block h2 {
  margin-bottom: 30px;
}
.uct_two_block h3 {
  margin-bottom: 12px;
}
.uct_two_block p {
  margin-bottom: 20px;
}
.uct_two_block .gb-block-layout-column {
  padding-right: 100px;
}
.uct_two_block .gb-block-layout-column.invisible {
  display: none;
}

.contact-card {
  background-color: #e9f7fc;
  border-radius: 30px;
  padding: 40px 40px 50px;
  margin-top: 90px;
}
.contact-card figure {
  background-color: #015ba6;
}
.contact-card figure img {
  width: 20px;
  height: auto;
}
.contact-card h2 {
  line-height: 43px;
  margin-bottom: 20px;
}
.contact-card p {
  font-weight: 500;
  line-height: 25px;
  margin-bottom: 0;
}
.contact-card .gb-block-layout-column:nth-child(2) {
  align-self: flex-end;
}
.contact-card .wp-block-media-text {
  display: flex;
  margin-bottom: 25px;
}
.contact-card .wp-block-media-text:nth-child(2) {
  margin-bottom: 0;
}
.contact-card .wp-block-media-text__media {
  border-radius: 50%;
  padding: 15px;
  width: 50px;
  height: 50px;
  background-color: #015ba6;
  display: flex;
}
.contact-card .wp-block-media-text__content {
  padding: 0;
}
.contact-card .wp-block-media-text__content p {
  padding-left: 15px;
  line-height: 27px;
}

.dynamic-latest-posts > li {
  background-color: #e9f7fc;
  padding: 40px 60px 40px 40px;
  display: grid;
  border-radius: 30px;
}
.dynamic-latest-posts time {
  order: 2;
}

.wpcf7-form p {
  display: flex;
  flex-wrap: wrap;
  color: #015ba6;
  column-gap: 10px;
  margin-bottom: 30px;
}
.wpcf7-form p.text-dark-blue {
  color: #003864;
}
.wpcf7-form p a {
  text-decoration: underline;
}
.wpcf7-form label {
  font-weight: 500;
  flex: 1 1 auto;
}
.wpcf7-form .wpcf7-form-control-wrap {
  display: flex;
  margin-top: 10px;
}
.wpcf7-form input {
  min-width: 440px;
  padding-left: 20px;
  width: 100%;
}
.wpcf7-form input[type=checkbox] {
  min-width: 0;
  width: auto;
  min-height: 0;
}
.wpcf7-form label select.custom-select {
  margin-bottom: 0;
  width: 440px;
}
.wpcf7-form .msg {
  width: 100%;
}
.wpcf7-form .wpcf7-submit {
  padding: 12px 30px;
  background-color: #015ba6;
  width: auto;
  min-width: 200px;
  border-radius: 30px;
  color: #fff;
  text-align: center;
  min-height: 0;
  margin-top: 20px;
}

*[vue] table {
  width: 100%;
}
*[vue] table td, *[vue] table th {
  border-left: 1px solid #D6D6D6;
  border-right: 1px solid #D6D6D6;
  padding: 0.85em 0.75em;
  border-bottom: 1px solid #D6D6D6;
}
*[vue] table tr:first-child td {
  border-top: 1px solid #D6D6D6;
}
*[vue] .v-menu__content {
  background-color: white;
}

.elevation-1 .markdown p {
  font-size: 0.875rem !important;
}
.elevation-1 .v-btn .v-btn__content .v-icon {
  color: white;
}

h2.text-xs-center + .container + .elevation-1:not(article .elevation-1) {
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 15px;
}

#app .v-card__title.fixed {
  z-index: 100;
}

@media only screen and (min-width: 1200px) {
  h2.text-xs-center + .container + .elevation-1 {
    margin: 0 -150px;
  }

  *[vue] .v-menu__content {
    background-color: white;
    transform: translateX(calc(-45vw + 600px));
  }
}
.post-header {
  background-color: #e9f7fc;
  padding: 70px 70px 50px;
  border-radius: 30px;
  margin-bottom: 50px;
}

.post-title {
  margin-bottom: 22px;
}

.post-date {
  margin-bottom: 24px;
}

.post-author {
  color: #015ba6;
}

.type-post .yoast-breadcrumbs {
  font-size: 14px;
}
.type-post .yoast-breadcrumbs span {
  color: #003864;
}
.type-post .main-para {
  font-size: 20px;
  line-height: 31px;
  margin-bottom: 12px;
}
.type-post > h2 {
  margin-bottom: 21px;
  line-height: 43px;
}
.type-post > h3 {
  margin-bottom: 14px;
}
.type-post hr.is-style-wide {
  margin-top: 82px;
}
.type-post .uct_cards {
  margin-top: 50px;
}
.type-post .uct_cards .three-card-item p {
  color: #333;
  margin-bottom: 15px;
}
.type-post .uct_cards .three-card-item p:last-of-type {
  font-weight: 600;
  margin-bottom: 0;
}

.home h1 {
  margin-bottom: 40px;
}
.home h2 {
  margin-bottom: 24px;
}

.banner_image {
  display: flex;
  justify-content: space-between;
}
.banner_image h1 {
  margin-bottom: 30px;
}
.banner_image .wp-block-media-text__media {
  flex: 1 1 0;
  padding: 0 50px 0 0;
  align-self: flex-start;
  position: relative;
}
.banner_image .wp-block-media-text__media img {
  max-width: 544px;
  border-radius: 20px;
  min-height: 366px;
  object-fit: cover;
  object-position: center;
}
.banner_image .wp-block-media-text__media::after {
  content: "";
  display: block;
  background-color: #e9f7fc;
  position: absolute;
  top: 67px;
  left: 0;
  bottom: 0;
  right: 50px;
  z-index: -1;
  border-radius: 30px;
}
.banner_image .wp-block-media-text__content {
  flex: 1 1 0;
  padding: 50px 0 0 50px;
  align-self: flex-start;
}
.banner_image .wp-block-media-text__content .wp-block-buttons {
  margin-top: 40px;
  column-gap: 20px;
}
.banner_image .wp-block-media-text__content > .wp-block-media-text__media {
  /*this will shown in mobile view; can't change html - gutenburg generated*/
  display: none;
}
.banner_image .wp-block-button__link {
  background-color: #015ba6;
  color: #fff;
  font-size: 0.875rem;
  text-decoration: none;
  font-weight: 500;
  min-width: 240px;
  min-height: 50px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.video_info {
  background-color: #e9f7fc;
  padding: 40px 60px 80px 80px;
  border-radius: 30px;
  margin-top: 90px;
}
.video_info .wp-block-columns {
  margin-bottom: 0;
}
.video_info .wp-block-button__link {
  color: #015ba6;
  font-weight: 700;
}

.oxide-card {
  margin-bottom: 20px;
}
.oxide-card .gb-block-layout-column-inner p {
  margin-bottom: 30px;
}
.oxide-card .pill-download p {
  margin-bottom: 0;
}
.oxide-card .wp-block-button.btn-pm {
  position: static;
  display: flex;
  color: #fff;
}
.oxide-card .btn-pm img {
  margin-left: 12px;
  margin-top: -2px;
}

.two-cols h3 {
  color: #003864;
}
.two-cols .gb-block-layout-column-gap-2 {
  grid-gap: 0 20px;
}
.two-cols .gb-block-layout-column-inner p {
  margin-bottom: 0;
}

.fy p.desc {
  color: #003864;
  line-height: 34px;
  margin-bottom: 50px;
}
.fy p.select-para {
  color: #015ba6;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 30px;
}
.fy .custom-select {
  margin-bottom: 80px;
}
.fy .custom-select.dropdown-toggle::after {
  display: none;
}
.fy .dropdown-menu {
  min-width: 350px;
}
.fy .gb-layout-column-wrap {
  display: flex;
  column-gap: 20px;
}
.fy .gb-block-layout-column-inner {
  background-color: #e9f7fc;
  padding: 46px 40px 56px;
  border-radius: 30px;
  margin-bottom: 20px;
  width: 100%;
  min-height: 202px;
}
.fy .gb-block-layout-column-inner > h5 {
  font-weight: 700;
  color: #003864;
  font-size: 18px;
}
.fy .gb-block-layout-column-inner > p {
  float: left;
  font-size: 48px;
  line-height: 60px;
  margin-right: 20px;
  padding-left: 8px;
  color: #015ba6;
}
.fy .gb-block-layout-column-inner .wp-block-button__link {
  background-color: #90C547;
  display: flex;
  column-gap: 7px;
  align-items: center;
  justify-content: space-between;
  min-width: 80px;
  padding: 5px 10px;
  color: #fff;
  border-radius: 10px;
  cursor: auto;
}
.fy .gb-block-layout-column-inner .wp-block-button__link > img {
  width: 21px;
  height: 15px;
}
.fy .gb-block-layout-column-inner .wp-block-button {
  margin-bottom: 0;
  margin-top: 15px;
}
.fy p.update {
  margin-top: 40px;
  color: #015ba6;
}

#post-2841 {
  color: #003864;
}
#post-2841 h2 {
  margin-bottom: 30px;
}
#post-2841 p {
  color: #003864;
}
#post-2841 #about-the-amt ~ .about-ul {
  margin-bottom: 83px;
}
#post-2841 #about-the-amt ~ .about-ul li {
  line-height: 25px;
}
#post-2841 .wp-block-image.regular {
  margin: 75px 0 30px 0;
}
#post-2841 .wp-block-image.regular figcaption {
  text-align: center;
  color: #003864;
  font-size: 14px;
  margin: 10px 0 0 17px;
}
#post-2841 #take-a-look + .wp-block-buttons {
  margin-bottom: 20px;
}
#post-2841 #take-a-look + .wp-block-buttons .wp-block-button {
  margin: 0;
}
#post-2841 #take-a-look + .wp-block-buttons .wp-block-button__link {
  padding: 13px 36px;
  display: flex;
  column-gap: 16px;
}
#post-2841 #take-a-look + .wp-block-buttons img {
  margin-bottom: 2px;
}
#post-2841 .heading-ol {
  margin: 74px 0 22px;
}
#post-2841 #who-use-snomed {
  margin-bottom: 70px;
}
#post-2841 .text-button-block {
  align-items: center;
  margin-top: 5px;
}
#post-2841 .text-button-block.wp-block-columns {
  margin-bottom: 20px;
}
#post-2841 .text-button-block .wp-block-column {
  min-width: auto;
}
#post-2841 .text-button-block .wp-block-column:first-child {
  flex: 0 0 auto;
}
#post-2841 .text-button-block .wp-block-column:not(:first-child) {
  margin: 0;
}
#post-2841 .text-button-block p {
  margin-bottom: 0;
  font-weight: 600;
  margin-right: 10px;
}
#post-2841 .wp-block-buttons .download {
  margin-bottom: 0;
}
#post-2841 .download .wp-block-button__link {
  display: inline-flex;
  line-height: 18px;
  color: #003864;
  background-color: #e9f7fc;
  padding: 6px 10px;
}
#post-2841 .uct_cards p {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  color: #003864;
}

/*All rules inherited from components*/
#post-3099 .desc {
  margin-bottom: 0;
}
#post-3099 h2 {
  margin-bottom: 38px;
}
#post-3099 h3 {
  color: #015ba6;
}
#post-3099 .uct_cards .three-card-item {
  padding: 30px 30px 50px;
}

.three-card-block .three-card-item.empty {
  visibility: hidden;
  opacity: 0;
}

.banner_image.contact-page {
  margin-bottom: 60px;
}
.banner_image.contact-page .wp-block-media-text__content p {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 26px;
}
.banner_image.contact-page ul {
  font-size: 18px;
  color: #003864;
}
.banner_image.contact-page ul > li {
  margin-bottom: 8px;
}
.banner_image.contact-page li > img {
  margin-right: 20px;
  position: relative;
  bottom: 1px;
}

.news-events h1 {
  margin-bottom: 40px;
}
.news-events p.desc {
  line-height: 34px;
  margin-bottom: 65px;
  color: #003864;
  max-width: 900px;
}
.news-events .post-date {
  font-size: 14px;
  font-weight: 600;
  color: #333;
  margin-top: 20px;
}
.news-events .uct_cards {
  grid-gap: 0 0;
}
.news-events .three-card-item {
  height: 328px;
  justify-content: flex-start;
  overflow: auto;
  padding: 32px 60px 0 40px;
}
.news-events .news-col {
  margin-bottom: 30px;
}

.inner .entry-title {
  margin-bottom: 40px;
}
.inner .yoast-breadcrumbs {
  order: -1;
  margin-bottom: 40px;
  font-size: 14px;
}
.inner .yoast-breadcrumbs span {
  color: #003864;
}
.inner .yoast-breadcrumbs a {
  color: #015ba6;
  font-weight: 400;
}

.page-id-2687 h2 {
  margin-bottom: 50px;
}
.page-id-2687 p.desc {
  margin-bottom: 0;
}
.page-id-2687 .wp-block-genesis-blocks-gb-column p {
  color: #015ba6;
  font-weight: 600;
}
.page-id-2687 .wp-block-genesis-blocks-gb-column:nth-child(2) p {
  color: #003864;
  font-weight: 400;
}
.page-id-2687 .gb-layout-columns-2 + hr.wp-block-separator.is-style-wide:not(.thin) {
  margin: 76px 0 50px;
}

/*All styles inhertid from globals and components*/
.search .page-header {
  margin-bottom: 0;
}
.search .custom-select {
  width: 100%;
}
.search .dynamic-page-count {
  margin-top: 66px;
  font-weight: 600;
}

#post-3206 .wpcf7 {
  margin-top: 40px;
}

#post-2672 h2 {
  margin-bottom: 30px;
}
#post-2672 .desc {
  margin-bottom: 60px;
}

#post-3057 #more-info ~ p:last-of-type {
  margin-bottom: 30px;
}
#post-3057 .pill-download .wp-block-button__link {
  background-color: #fff;
}

.gb-block-accordion + .gb-block-accordion {
  margin: -2px 0 0px 0;
}

.gb-block-accordion {
  margin-bottom: 0;
}
.gb-block-accordion > details {
  padding: 35px 50px 35px 30px;
  border-radius: 30px;
  position: relative;
}
.gb-block-accordion > details[open] {
  background-color: #e9f7fc;
  border-radius: 30px;
  margin-bottom: 10px;
}
.gb-block-accordion > details:not([open])::after {
  content: " ";
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #e9f7fc;
  bottom: 0;
}
.gb-block-accordion .gb-accordion-title {
  background-color: transparent;
  padding: 0 0 0 40px;
  list-style-type: none;
  position: relative;
  color: #015ba6;
  font-weight: 600;
}
.gb-block-accordion .gb-accordion-title:before {
  content: url("../icons/arrow_details.svg");
  position: absolute;
  left: 0;
}
.gb-block-accordion details[open] .gb-accordion-title:before {
  transform: rotateZ(-180deg);
  left: 0;
  top: -6px;
}
.gb-block-accordion .gb-accordion-text {
  padding: 30px 0 0 0;
  color: #003864;
}
.gb-block-accordion .gb-accordion-text > * {
  padding: 0 0 0 40px;
}
.gb-block-accordion .gb-accordion-text > ul,
.gb-block-accordion .gb-accordion-text > ol {
  padding: 0 0 0 55px;
}

#post-2677 .entry-title {
  margin-bottom: 20px;
}

.contact-icons {
  margin-bottom: 0;
}
.contact-icons .wp-block-media-text__media {
  border-radius: 50%;
  padding: 15px;
  width: 50px;
  height: 50px;
  background-color: #015ba6;
  display: flex;
}
.contact-icons .wp-block-media-text__content {
  padding: 0;
}
.contact-icons .wp-block-media-text__content p {
  font-size: 18px;
  padding-left: 15px;
  line-height: 34px;
  margin: 0;
}
.contact-icons ~ .wp-block-group h2 {
  margin-bottom: 40px;
}
.contact-icons ~ .wp-block-group .gb-block-layout-column-inner p {
  color: #003864;
  line-height: 30px;
  margin-bottom: 0;
}
.contact-icons ~ .wp-block-group .gb-block-layout-column-inner a {
  color: #015ba6;
  font-weight: 400;
  text-decoration: none;
}

@media only screen and (max-width: 1399.98px) {
  .page-container .container {
    max-width: 1140px;
  }

  .inner .container {
    /*inner pages only*/
    max-width: 900px;
  }

  .banner_image .wp-block-media-text__media {
    padding: 0 76px 0 0;
  }
  .banner_image .wp-block-media-text__media:after {
    right: 40px;
  }
  .banner_image .wp-block-media-text__content {
    padding: 40px 0 0 40px;
  }
}
@media only screen and (max-width: 1199.98px) {
  .page-container .container {
    max-width: 920px;
  }

  .inner .container {
    /*inner pages only*/
    max-width: 900px;
  }

  .banner_image .wp-block-media-text__media {
    padding: 0 76px 0 0;
  }
  .banner_image .wp-block-media-text__media:after {
    right: 35px;
  }
  .banner_image .wp-block-media-text__content {
    padding: 40px 0 0 30px;
  }
  .banner_image .wp-block-button__link {
    min-height: 40px;
    margin-bottom: 10px;
  }

  .uct_cards .three-card-item {
    padding: 30px 40px 75px 30px;
  }
}
@media only screen and (max-width: 991.98px) {
  .entry-content h1 {
    margin-top: 0;
  }

  .gb-layout-columns-3 > .gb-layout-column-wrap.gb-is-responsive-column {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    grid-template-areas: "col1" "col2" "col3";
  }

  .page-container .container {
    max-width: 720px;
  }
  .page-container .components {
    padding: 50px 30px;
  }

  .components .wp-block-separator:not(.thin) {
    margin: 70px 0 30px 0;
  }
  .components .pagination {
    justify-content: center;
  }
  .components .pagination .gb-block-layout-column {
    justify-content: center;
  }
  .components .pagination > .gb-block-layout-column-gap-2 {
    flex-direction: column;
    row-gap: 30px;
  }
  .components .pagination .wp-block-buttons {
    row-gap: 24px;
  }
  .components .pagination .gb-layout-columns-2 > .gb-layout-column-wrap {
    flex-wrap: wrap;
    row-gap: 10px;
    justify-content: center;
  }
  .components .uct_cards + .wp-block-separator:not(.thin) {
    margin: 50px 0 30px 0;
  }

  .pill-long.gb-layout-columns-2 > .gb-layout-column-wrap {
    grid-template-columns: 65fr 24fr;
  }

  .banner_image > .wp-block-media-text__media {
    display: none;
  }
  .banner_image .wp-block-media-text__content {
    padding: 0;
  }
  .banner_image .wp-block-media-text__content > .wp-block-media-text__media {
    display: block;
    /*this will shown in mobile view; can't change html - gutenburg generated*/
    margin: 30px 0;
  }
  .banner_image .wp-block-media-text__content .wp-block-buttons {
    margin-top: 30px;
  }
  .banner_image .wp-block-media-text__media {
    padding: 0 36px 0 0;
  }
  .banner_image .wp-block-media-text__media:after {
    right: 0;
  }
  .banner_image .wp-block-media-text__media img {
    max-width: none;
    min-height: 0;
  }

  .banner_image.reverse .wp-block-media-text__content {
    padding: 0;
  }
  .banner_image.reverse .wp-block-media-text__media {
    padding: 0 0 0 36px;
  }
  .banner_image.reverse .wp-block-media-text__media::after {
    left: 0;
    right: 35px;
  }

  .video_info {
    margin-top: 45px;
    padding: 30px 45px 60px 60px;
  }

  .wp-block-columns {
    margin-bottom: 0;
  }

  .three-card-block {
    flex-direction: column;
    grid-gap: 0;
  }
  .three-card-block .three-card-item {
    margin-bottom: 20px;
  }
  .three-card-block .three-card-item.invisible {
    padding: 0;
    margin: 0;
    height: 0;
  }
  .three-card-block .three-card-item.invisible h3 {
    margin: 0;
  }
  .three-card-block .three-card-item.empty {
    display: none;
  }

  .contact-card h2 {
    line-height: 1.2;
  }

  .news-col .three-card-item {
    margin-bottom: 0;
    height: auto;
    padding: 32px 60px 75px 40px;
  }

  .fy .gb-block-layout-column-inner {
    min-height: 202px;
  }

  .contact-icons + .wp-block-separator:not(.thin) {
    margin: 70px 0 50px 0;
  }
  .contact-icons ~ .wp-block-group .gb-block-layout-column {
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 767.98px) {
  h1 {
    margin-bottom: 1rem;
    font-size: 1.75rem;
  }

  h2 {
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    line-height: 1.4;
  }

  .page-container .components {
    padding: 30px;
  }

  main#content {
    padding: 0 15px;
  }

  .page-template-template-page-builder-php #content {
    padding: 0 15px !important;
  }

  main:not(.inner) > .container {
    padding: 30px 15px 30px;
  }

  main.inner > .container {
    padding: 25px 15px 30px;
  }

  .inner .yoast-breadcrumbs {
    margin-bottom: 20px;
  }

  .inner .entry-title {
    margin-bottom: 1rem;
  }

  /*Bootstrap wordpress overrides*/
  .gb-layout-columns-2 > .gb-layout-column-wrap.gb-is-responsive-column {
    grid-template-columns: 1fr;
    grid-template-areas: "col1" "col2";
  }

  .components h2 {
    font-size: 1.5rem;
  }
  .components p.desc {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 30px;
  }
  .components .gb-2-col-wideright > .gb-layout-column-wrap {
    grid-template-columns: 1fr;
  }
  .components .wp-block-separator:not(.thin) {
    margin: 50px 0 25px 0;
  }
  .components .d-flex p + .wp-block-separator:not(.thin) {
    margin: 34px 0 25px 0;
  }
  .components .uct_cards + .wp-block-separator:not(.thin) {
    margin: 30px 0 25px 0;
  }
  .components .uct_cards .three-card-item {
    padding: 30px 20px 50px 20px;
  }
  .components .uct_cards .three-card-item.invisible {
    padding: 0;
  }
  .components .uct_cards .three-card-item .wp-block-button {
    bottom: 10px;
  }
  .components .three-card-item h3 {
    line-height: 1.2;
    margin-bottom: 8px;
  }
  .components .oxide-card {
    padding: 35px;
  }
  .components .oxide-card + hr.wp-block-separator.is-style-wide:not(.thin) {
    margin: 30px 0 25px 0;
    margin-top: 30px;
  }
  .components .pagination {
    margin-top: 0;
    margin-bottom: 10px;
  }
  .components article .pill-img {
    padding: 20px;
  }
  .components article .pill-img figure {
    width: 40px;
    height: 40px;
  }
  .components article .pill-img p {
    font-size: 1rem;
  }
  .components .pill-img.gb-layout-columns-2 > .gb-layout-column-wrap {
    grid-gap: 15px;
    grid-template-columns: 40px 1fr;
    grid-template-areas: "col1 col2";
  }

  .ie .gb-layout-column-wrap {
    flex-direction: column;
  }

  .banner_image h1 {
    margin-bottom: 1rem;
  }
  .banner_image .wp-block-media-text__media:after {
    left: 36px;
    bottom: -36px;
    top: 36px;
  }
  .banner_image .wp-block-media-text__content .wp-block-media-text__media {
    margin: 20px 0 46px;
  }
  .banner_image .wp-block-media-text__content .wp-block-buttons {
    margin-top: 20px;
  }
  .banner_image.contact-page {
    margin-bottom: 16px;
  }
  .banner_image.contact-page ul {
    font-size: 1rem;
    margin-top: -16px;
  }

  .banner_image.reverse .wp-block-media-text__content p {
    font-size: 1rem;
  }
  .banner_image.reverse .wp-block-media-text__media {
    padding: 0 0 0 26px;
  }
  .banner_image.reverse .wp-block-media-text__media:after {
    bottom: -26px;
  }

  .wp-block-embed-youtube {
    margin-top: 20px;
  }

  .entry-content > .wp-block-group:first-child + .wp-block-separator:not(.thin) {
    margin: 25px 0 25px 0;
  }

  .snomed-heading {
    margin-bottom: 24px;
  }

  .video_info {
    margin-top: 30px;
  }

  .wp-block-column:not(:only-child) {
    flex-basis: 100% !important;
  }

  .wp-block-column:nth-child(2n) {
    margin-left: 0;
  }

  .pill-long {
    padding: 20px;
  }
  .pill-long p {
    font-size: 1rem;
    margin-left: 60px;
  }
  .pill-long p:before {
    width: 40px;
    height: 40px;
    left: -60px;
  }
  .pill-long p img {
    margin-left: 15px;
    height: 24px;
    width: auto !important;
  }
  .pill-long.gb-layout-columns-2 > .gb-layout-column-wrap.gb-is-responsive-column {
    grid-template-columns: 1fr;
    grid-template-areas: "col1" "col2";
  }

  .long-pill-columns {
    margin-bottom: 14px;
  }

  .check-list li {
    font-size: 1rem;
  }

  .caption-img {
    padding-top: 20px;
    row-gap: 20px;
  }
  .caption-img figcaption {
    padding: 20px;
  }

  .contact-card p {
    margin-bottom: 16px;
  }
  .contact-card .wp-block-media-text {
    margin-bottom: 0;
  }

  .ref th:first-child, .ref td:first-child, .ref-multi th:first-child, .ref-multi td:first-child {
    padding-right: 22.5px;
  }
  .ref th:last-child, .ref td:last-child, .ref-multi th:last-child, .ref-multi td:last-child {
    padding-left: 22.5px;
  }

  .ref-multi.wp-block-table th, .ref-multi.wp-block-table td {
    padding-right: 22.5px;
  }
  .ref-multi th:last-child, .ref-multi td:last-child {
    padding-left: 0;
  }

  .home h2 {
    margin-bottom: 0.5rem;
  }

  .news-col .three-card-item {
    margin-bottom: 0;
    height: auto;
    padding: 32px;
  }

  .fy p.desc {
    margin-bottom: 30px;
  }
  .fy p.select-para {
    margin-bottom: 16px;
  }
  .fy .custom-select {
    margin-bottom: 24px;
  }
  .fy p.update {
    margin-top: 0;
  }
  .fy .gb-layout-column-wrap {
    flex-direction: column;
  }
  .fy .gb-block-layout-column-inner {
    min-height: 0;
    padding: 26px 20px 36px;
  }
  .fy .gb-block-layout-column-inner > p {
    padding-left: 0;
    font-size: 2rem;
    line-height: 1.1;
  }
  .fy .gb-block-layout-column-inner .wp-block-button {
    margin-top: 1px;
  }

  .news-events h1 {
    margin-bottom: 1rem;
  }

  .gb-block-accordion > details[open],
.gb-block-accordion > details {
    padding: 20px 20px 20px 20px;
  }

  .gb-block-accordion > details:not([open])::after {
    left: 0;
  }

  .gb-block-accordion .gb-accordion-text {
    padding: 15px 0 0 0;
  }

  .gb-block-accordion .gb-accordion-text p {
    padding-left: 0;
  }

  .gb-block-accordion .gb-accordion-text > * {
    padding: 0 0 0 0px;
  }

  .gb-block-accordion .gb-accordion-text > ul,
.gb-block-accordion .gb-accordion-text > ol {
    padding: 0 0 0 15px;
  }

  .page-id-2687 p.desc {
    margin-bottom: 0;
  }
  .page-id-2687 h2 {
    margin-bottom: 12px;
  }
  .page-id-2687 .wp-block-genesis-blocks-gb-column p {
    margin-bottom: 8px;
  }
  .page-id-2687 hr.wp-block-separator.thin.is-style-wide {
    margin: 8px 0 16px;
  }
  .page-id-2687 .gb-layout-columns-2 + hr.wp-block-separator.is-style-wide:not(.thin) {
    margin: 42px 0 25px;
  }

  .type-post > h2 {
    margin-bottom: 8px;
    line-height: 1.2;
  }
  .type-post > h3 {
    margin-bottom: 8px;
    line-height: 1.2;
  }
  .type-post .main-para {
    font-size: 1rem;
    margin-bottom: 0;
    line-height: 1.5;
  }
  .type-post .uct_cards {
    margin-top: 17px;
  }

  .post-header {
    padding: 20px 20px 15px;
    margin-bottom: 20px;
  }

  .post-title {
    margin-bottom: 16px;
  }

  .post-date {
    margin-bottom: 8px;
  }

  .post-author {
    margin-bottom: 0;
  }

  #post-3057 #more-info ~ p:last-of-type {
    margin-bottom: 10px;
  }

  .contact-icons ~ .wp-block-group h2 {
    margin-bottom: 1rem;
  }
  .contact-icons .wp-block-media-text__content p {
    font-size: 1rem;
  }
  .contact-icons .gb-block-layout-column {
    margin-bottom: 10px;
  }
  .contact-icons .wp-block-media-text.is-stacked-on-mobile {
    grid-template-columns: 50px auto !important;
  }
  .contact-icons .wp-block-media-text.is-stacked-on-mobile .wp-block-media-text__media {
    grid-column: 1/2;
  }
  .contact-icons .wp-block-media-text.is-stacked-on-mobile .wp-block-media-text__content {
    grid-column: 2/3;
    grid-row: 1/2;
    margin-top: 16px;
  }
}
@media only screen and (max-width: 575.98px) {
  .wp-block-buttons {
    flex-direction: column;
    align-items: stretch;
  }
  .wp-block-buttons > .wp-block-button {
    margin-bottom: 0;
  }

  .components .wp-block-button__link {
    min-width: auto;
    width: 100%;
  }
  .components .custom-select {
    width: 100%;
  }
  .components .uct_cards .three-card-item {
    padding: 30px 20px 50px 20px;
  }
  .components .uct_cards .three-card-item .wp-block-button {
    bottom: 10px;
  }

  .video_info {
    padding: 30px;
  }

  .uct_two_block {
    margin: -30px 0 0 0;
  }
  .uct_two_block h2 {
    margin-bottom: 20px;
  }
  .uct_two_block .wp-block-separator:not(.thin) {
    margin: 60px 0 25px 0;
  }
  .uct_two_block .gb-block-layout-column:nth-child(2) .wp-block-separator:not(.thin) {
    margin: 20px 0 25px 0;
  }
  .uct_two_block .gb-block-layout-column:nth-child(1) .wp-block-button {
    margin-bottom: 25px;
  }
  .uct_two_block .gb-block-layout-column {
    padding-right: 0;
  }

  .oxide-card .wp-block-button__link {
    width: auto;
  }

  .table-third-party .gb-block-layout-column:nth-child(2) th,
.table-third-party .gb-block-layout-column:nth-child(2) td {
    padding-left: 0;
  }

  .fy .dropdown-menu {
    min-width: 100%;
  }

  .banner_image .wp-block-media-text__media {
    padding: 0 26px 0 0;
  }
  .banner_image .wp-block-media-text__media:after {
    left: 26px;
    bottom: -26px;
    top: 26px;
  }

  .fy .wp-block-button__link {
    width: 80px;
  }
}
/*
  - desc  
  - seperator (always choose wide-line in settings)  
  - list  (this is default list component of gutenberg editor)  
  - oxide_image_regular  
  - btn_pm
  - oxide_card_with_btn
  - pill_lg_btn
1. card_oxide
2. nav_list
3. table_oxide  
4. pill-img, pill-long-no-button  
5. caption_image  
 - blue_list_unordered_oxide
 - oxide_border_list
*/
/*Unwanted important rules removed by AK*/
#register, #signIn {
  display: none;
}

#register + .nav-link {
  display: none;
}

.skip-to-content-link {
  position: fixed;
  left: 50%;
  transform: translateY(-100%);
  background: #eee;
  left: 50%;
  padding: 16px 12px 12px;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  color: #003864;
  transform: translateY(-100%);
  transition: transform 0.3s;
  z-index: 105;
}

.skip-to-content-link:focus {
  transform: translateY(-4px);
}

.skip-to-content-link:hover {
  background: #eee;
}

#header-inner {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-height: 41px;
  max-width: 1350px;
  width: 100%;
  margin: auto;
}
#header-inner .navbar {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease;
}
#header-inner .dh-tag {
  white-space: nowrap;
  margin-right: auto;
  font-size: 14px;
  color: #fff;
}
#header-inner span.dh-tag a, #header-inner a.nav-link {
  color: #fff;
  font-weight: 500;
}
#header-inner span a {
  text-decoration: underline;
}
#header-inner .menu {
  display: flex;
  justify-content: space-around;
  text-decoration: none;
  height: 100%;
  padding: 0 !important;
  margin: 0 !important;
}
#header-inner .menu a {
  font-size: 14px;
  text-decoration: none;
}
#header-inner .menu a:hover {
  text-decoration: underline;
}
@media (max-width: 1024px) {
  #header-inner .menu .sign-in, #header-inner .menu .register, #header-inner .menu .content-requests, #header-inner .menu .news-and-events {
    display: none;
  }
}
#header-inner .menu .sign-in {
  position: relative;
}
#header-inner .menu .sign-in::before {
  content: "/" !important;
  position: absolute;
  left: -20px;
}
#header-inner .menu .current-menu-item {
  background-color: white;
  border-radius: 10px 10px 0 0;
  padding: 0 12px;
}
#header-inner .menu .current-menu-item a {
  color: #015ba6 !important;
  border-bottom: none;
}

#search-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: none;
}
#search-inner .navbar {
  width: 100%;
  position: static;
}
#search-inner .navbar .container {
  max-width: none;
  padding: 0px 20px;
}
#search-inner .navbar-brand img {
  max-width: 175px;
  overflow: hidden;
  height: auto;
}
#search-inner .search-menu a {
  font-size: 14px;
  font-weight: 500;
  text-decoration: none !important;
  color: #015ba6;
}
#search-inner .search-menu li {
  padding: 0;
}
@media (max-width: 1440px) {
  #search-inner .search-menu li {
    flex-flow: wrap;
  }
}

#search-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1320px;
  /* 1320 is the exact length of box shadow in search menu */
  width: 100%;
  background-color: white;
  margin: auto;
}
#search-bar .menu {
  padding: 0 !important;
  margin: 0 !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-around;
}
@media (max-width: 375px) {
  #search-bar .menu ul {
    display: none;
  }
}

.nav-shadow {
  position: relative;
  /*bottom: -10px;*/
}
.nav-shadow:after {
  content: "";
  position: relative;
  z-index: -1;
  bottom: -1px;
  -webkit-transform: scale(0.988);
  -o-transform: scale(0.988);
  transform: scale(0.988);
  -webkit-box-shadow: 0px -4px 8px 1px #000000;
  box-shadow: 0px -4px 8px 1px #000000;
  display: flex;
}
@media (max-width: 375px) {
  .nav-shadow:after {
    -webkit-transform: scale(0.96);
    -o-transform: scale(0.96);
    transform: scale(0.96);
  }
}

nav .search-menu {
  justify-content: flex-end;
}

.fa-search {
  color: #fff;
  width: 40px;
  height: 40px;
  position: relative;
  background-color: #33acde;
  border-radius: 50%;
  text-align-last: center;
  padding-top: 11.5px;
  transition: 0.3s ease-in-out;
}
.fa-search:before {
  position: absolute;
  top: 11px;
  left: 9.5px;
}
.fa-search:hover {
  box-shadow: 0 0 0 4px #ccdeed;
  transition: box-shadow 0.2s;
}
@media (max-width: 768px) {
  .fa-search {
    background-color: #ebebeb;
  }
}

.fa-bars {
  color: #fff;
  min-width: 36px;
  min-height: 36px;
  position: relative;
  transition: 0.3s ease-in-out;
  background-color: #25abe0;
  border-radius: 50%;
  text-align-last: center;
  padding: 10px;
}

#auth-nav menu {
  margin-bottom: 0 !important;
}
#auth-nav .user__sign-in {
  padding: 0 20px 0 0;
}
#auth-nav .user__sign-up {
  padding: 0 20px 0 0;
}
#auth-nav .user__sign-up:after {
  content: "/" !important;
  color: #fff;
  position: relative;
  right: -10px;
}
#auth-nav ul {
  padding: 0;
}
#auth-nav ul .menu-item {
  margin: 0;
}
#auth-nav ul .nav-item {
  margin: 0;
}
#auth-nav ul ul {
  display: flex;
  width: max-content;
}
/*Anupam khosla styles from here*/
.container#search-inner {
  padding: 30px 30px 30px;
}

.modal-open {
  /*For some reason, html elements is set to scroll, usually body element scrolls
    thats why padding-right in important  */
  padding-right: 0 !important;
}

.modal {
  padding-right: 0 !important;
}
.modal .form-group {
  margin-bottom: 20px;
  display: flex;
  column-gap: 40px;
}
.modal .form-check {
  margin-bottom: 60px;
  padding-left: 0;
  display: flex;
}
.modal .form-check-input[type=checkbox] {
  width: 30px;
  height: 30px;
  border-radius: 8px;
  margin-right: 25px;
}
.modal label {
  align-self: center;
  width: 50%;
}
.modal label select.custom-select {
  width: 100%;
}
.modal .forms h3 {
  margin-bottom: 24px;
}

#header-bar {
  background-color: #015ba6;
}
#header-bar > .container {
  max-width: 1320px;
  padding: 0 24px 0 30px;
}

.primary-menu {
  flex-direction: row;
  flex-grow: 0;
}
.primary-menu .navbar-nav {
  margin-left: auto;
}
.primary-menu li {
  margin-right: 40px;
  display: inline-flex;
  align-items: center;
}

.navbar-light .navbar-nav .nav-link {
  color: #fff;
}

.sub-menu {
  display: none;
}

.menu-item:focus .sub-menu,
.menu-item:hover .sub-menu {
  display: block;
}

#search-bar .circle-search {
  background-color: #33acde;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  margin-left: 34px;
}

#bs-example-navbar-collapse-1 {
  padding-top: 10px;
}

#menu-search-menu {
  flex-wrap: nowrap;
  align-items: flex-start;
  min-height: 51px;
}

.nav-bg {
  position: absolute;
  left: 70px;
  right: 60px;
  top: 158px;
  background-color: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  border-radius: 30px;
  z-index: 101;
  height: 0;
  padding: 0;
}

#search-bar .dropdown {
  position: static;
}
#search-bar #menu-search-menu > .menu-item {
  padding: 0 0 0 26px;
  flex-wrap: nowrap;
  margin-right: 48px;
}
#search-bar .menu-item {
  display: flex;
  z-index: 103;
}
#search-bar .menu-item > a.dropdown-item {
  border-radius: 10px;
  position: relative;
}
#search-bar .menu-item > a.dropdown-item span:after {
  content: "";
  height: 2px;
  width: 100%;
  background-color: #e9f7fc;
  position: absolute;
  bottom: 0;
  left: 0;
}
#search-bar .menu-item:hover > a.dropdown-item span:after, #search-bar .menu-item:focus > a.dropdown-item span:after {
  display: none;
}
#search-bar .menu-item.show > a.dropdown-item {
  background-color: #015ba6;
  color: #fff;
}
#search-bar .menu-item.show > a.dropdown-item span:after {
  display: none;
}
#search-bar .dropdown-menu {
  padding: 0;
  border: 0;
  left: 50%;
  transform: translate(-100%, 89px);
  top: auto;
  width: 290px;
}
#search-bar .dropdown-menu .menu-item {
  border-radius: 10px;
}
#search-bar .dropdown-menu .menu-item .dropdown-item:hover, #search-bar .dropdown-menu .menu-item .dropdown-item:focus {
  color: #fff;
  background-color: #015ba6;
}
#search-bar .dropdown-menu .focuseable {
  position: absolute;
  right: 10px;
  transform: translateY(13px);
  color: #fff;
}
#search-bar .dropdown-menu .focuseable:focus {
  color: #015ba6;
}
#search-bar .dropdown-menu .menu-item.show .focuseable {
  color: #fff;
}
#search-bar .dropdown-menu .dropdown-menu {
  transform: none;
  left: calc(100% + 40px);
  top: 0;
  width: 290px;
  padding: 30px;
  background-color: #e9f7fc;
  border-radius: 30px;
}
#search-bar .dropdown-menu .dropdown-menu .menu-item {
  margin-bottom: 25px;
}
#search-bar .dropdown-menu .dropdown-menu .menu-item:last-child {
  margin-bottom: 0;
}
#search-bar .dropdown-menu .dropdown-menu a.dropdown-item {
  padding: 0;
  border-radius: 0;
  display: inline;
}
#search-bar .dropdown-menu .dropdown-menu .dropdown-item:hover, #search-bar .dropdown-menu .dropdown-menu .dropdown-item:focus {
  color: #015ba6;
  background-color: transparent;
}
#search-bar .dropdown-item {
  white-space: normal;
  padding: 20px 30px;
  margin-bottom: 2px;
}
#search-bar .dropdown-toggle::after {
  display: none;
}
#search-bar .dropdown-toggle {
  width: 0;
  height: 0;
  position: absolute;
}
#search-bar .focuseable {
  opacity: 0;
  color: #015ba6;
  pointer-events: none;
}
#search-bar .oxide-dropdown-toggle:focus-visible ~ .focuseable {
  opacity: 1;
}
#search-bar .focuseable:focus-visible {
  opacity: 1;
}
#search-bar .focuseable::after {
  display: inline-block;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

#menu-search-menu > .dropdown > a.dropdown-item:hover, #menu-search-menu > .menu-item a.dropdown-item:focus {
  background-color: #015ba6;
  color: #fff;
}

.active-bar {
  position: absolute;
  height: 4px;
  background-color: #015ba6;
  width: 0;
  transform: translateY(47px);
}

.modal .wp-block-separator.is-style-wide {
  margin: 40px auto;
}
.modal p {
  margin-bottom: 32px;
}

.choose-type .wp-block-button__link {
  width: 100%;
  font-weight: 600;
}

.conditions .wp-block-button__link {
  min-width: 280px;
  margin-right: 40px;
}
.conditions .btn-pm {
  margin-top: 45px;
}

.register .btn {
  font-size: 14px;
}

@media only screen and (hover: none) {
  #search-bar .focuseable {
    opacity: 1;
    pointer-events: auto;
  }

  #search-bar .dropdown-menu .focuseable {
    color: #015ba6;
  }
}
@media only screen and (max-width: 1399.8px) {
  #search-bar #menu-search-menu > .menu-item {
    margin-right: 20px;
  }
}
@media only screen and (max-width: 1199.8px) {
  #search-inner .navbar-brand img {
    width: 110px;
  }

  #search-bar .circle-search {
    margin-left: 15px;
  }

  #search-bar #menu-search-menu > .menu-item {
    margin-right: 0px;
    padding: 0 0 0 7px;
  }
}
@media only screen and (max-width: 991.8px) {
  .navbar-nav .nav-link {
    padding: 0;
  }

  .nav-bg {
    display: none;
  }

  #common-header {
    display: flex;
    flex-direction: column;
  }
  #common-header {
    content: "";
    -webkit-box-shadow: 0px -4px 8px 1px #000000;
    box-shadow: 0px -4px 8px 1px #000000;
  }

  #header-bar {
    order: 1;
    background-color: transparent;
  }
  #header-bar > .container {
    padding: 0 30px 32px;
  }
  #header-bar a, #header-bar span {
    color: #015ba6;
  }

  #header-inner {
    margin-right: auto;
    border-top: 1px solid #D6D6D6;
    padding-top: 15px;
  }
  #header-inner > nav {
    align-items: flex-start;
  }
  #header-inner .dh-tag {
    margin: 10px auto 10px 0;
    display: block;
  }
  #header-inner span.dh-tag, #header-inner span.dh-tag a, #header-inner a.nav-link {
    color: #015ba6;
  }
  #header-inner .menu-item {
    margin: 0 0 10px 0;
  }
  #header-inner #register {
    margin: 0 0 10px 0 !important;
    /*Wierd bug, bootstrap modal causes normal margin to not work!*/
  }
  #header-inner #register span,
#header-inner #signIn span {
    line-height: 17px;
  }

  #primary-menu-container > .nav {
    flex-direction: row;
    flex-wrap: wrap;
  }
  #primary-menu-container .menu-item {
    width: 100%;
    min-height: 25px;
  }
  #primary-menu-container button.menu-item {
    width: auto;
  }
  #primary-menu-container li.nav-link {
    width: auto;
    margin: 0 10px 10px;
    color: #015ba6;
  }

  #search-bar #menu-search-menu > .menu-item {
    padding: 0;
  }
  #search-bar .focuseable {
    margin-left: auto;
    padding: 2px 6px;
    opacity: 1;
    color: #015ba6;
    align-self: center;
    pointer-events: auto;
  }
  #search-bar .dropdown-menu {
    position: static;
    transform: none;
    width: 100%;
    margin-top: 12px;
    padding-left: 15px;
    background-color: transparent;
  }
  #search-bar .dropdown-menu .focuseable {
    position: static;
    transform: none;
    color: #015ba6;
  }
  #search-bar .dropdown-menu .menu-item .dropdown-item:focus,
#search-bar .dropdown-menu .menu-item .dropdown-item:hover {
    color: #015ba6;
    background-color: transparent;
  }
  #search-bar .dropdown-menu .menu-item.show .focuseable {
    color: #015ba6;
  }
  #search-bar .dropdown-menu .dropdown-menu {
    position: static;
    width: 100%;
    background-color: transparent;
    padding: 0 0 0 15px;
  }
  #search-bar .dropdown-menu .dropdown-menu .menu-item {
    margin-bottom: 0;
  }
  #search-bar .dropdown-menu .menu-item {
    border-bottom: 0;
  }
  #search-bar .menu-item > a.dropdown-item span:after {
    display: none;
  }
  #search-bar .menu-item.show > a.dropdown-item {
    background-color: transparent;
    color: #015ba6;
  }
  #search-bar .menu-item.show > .focuseable {
    color: #015ba6;
  }
  #search-bar .dropdown-item {
    padding: 0;
    width: auto;
    align-self: center;
  }
  #search-bar .nav-link {
    padding: 0;
    align-self: center;
  }
  #search-bar #bs-example-navbar-collapse-1 .menu-item {
    flex-wrap: wrap;
    width: 100%;
    margin: 0 0 10px 0;
    min-height: 25px;
  }

  #bs-example-navbar-collapse-1 {
    padding-top: 0;
  }

  #bs-example-navbar-collapse-1 > ul > .current-menu-item {
    position: relative;
  }
  #bs-example-navbar-collapse-1 > ul > .current-menu-item::before {
    content: "";
    width: 4px;
    height: 100%;
    background-color: #015ba6;
    position: absolute;
    left: -30px;
  }

  .container#search-inner {
    padding: 30px 30px 15px;
  }

  #menu-search-menu {
    height: auto;
    padding-top: 30px;
    align-items: flex-start;
  }

  .nav-shadow:after {
    display: none;
  }
}
@media only screen and (max-width: 575.8px) {
  .container#search-inner {
    padding: 15px;
  }

  #header-bar > .container {
    padding: 0 15px 20px;
  }

  #menu-search-menu {
    padding-top: 15px;
  }

  #search-bar #menu-search-menu .menu-item {
    margin: 0 0 8px 0;
  }

  #search-bar .dropdown-menu {
    margin-top: 8px;
  }

  #bs-example-navbar-collapse-1 > ul > .current-menu-item::before {
    content: "";
    width: 4px;
    height: 100%;
    background-color: #015ba6;
    position: absolute;
    left: -15px;
  }

  #header-inner .dh-tag {
    margin: 8px auto 8px 0;
  }

  #header-inner .menu-item {
    margin: 0 0 8px 0;
  }

  #header-inner #register {
    margin: 0 0 8px 0 !important;
  }

  #primary-menu-container li.nav-link {
    margin: 0 10px 8px;
  }
}
.site-footer {
  background-color: #003864;
  color: #fff;
  overflow-x: hidden;
}
.site-footer li, .site-footer a, .site-footer p {
  color: #fff;
}
.site-footer #footer-1 {
  padding: 40px 0 24px 0;
  align-items: center;
}
.site-footer #footer-1 a:hover {
  text-decoration: none;
  color: white;
}
.site-footer #footer-2 {
  margin-bottom: 32px;
}
.site-footer #footer-2 ul, .site-footer #footer-2 p {
  padding: 0;
}
.site-footer #footer-2 .about-news {
  margin-bottom: 25px;
}
.site-footer #footer-3 {
  margin: 0;
}
.site-footer #footer-3 div {
  padding: 0;
}
.site-footer #footer-3 a {
  text-decoration: none;
}
.site-footer #footer-3 a:hover {
  text-decoration: underline;
  color: white;
}
.site-footer .footer-menu > .menu {
  display: flex;
  justify-content: space-between;
  padding-left: 15px;
}

/*AK code starts*/
.scroll-btn {
  pointer-events: none;
  position: sticky;
  margin-top: -20px;
  padding: 0 20px 20px 0;
  bottom: 0px;
  color: #015ba6;
  overflow: auto;
  z-index: 2;
  min-height: 40px;
}

#smoothup {
  visibility: hidden;
  pointer-events: auto;
  display: flex;
  float: right;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
}

#smoothup button {
  border-radius: 50px;
  background-color: #015ba6;
  width: 40px;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  outline-offset: -1px;
}

footer .navbar-nav .nav-link {
  padding: 0;
  display: inline;
}
footer .dropdown-toggle::after {
  display: none;
}
footer .menu-item:not(:last-child) {
  margin-bottom: 25px;
  font-weight: 500;
}
footer .menu-item:last-child {
  margin-bottom: 0;
}

#menu-search-menu-1 {
  display: block;
}
#menu-search-menu-1 button {
  display: none;
}

.footer-menu .menu-item:not(:last-child) {
  margin-bottom: 0;
}

#site-footer hr.wp-block-separator.thin.is-style-wide {
  border-bottom-color: #33ACDE;
}

.footer-inner {
  margin: auto;
  max-width: 1260px;
}

.footer-inner.container {
  padding: 0;
}

.social-icons {
  text-align: right;
}
.social-icons .icon {
  margin-right: 40px;
}

@media only screen and (max-width: 1399.98px) {
  .scroll-btn.container {
    max-width: 1140px;
  }

  .footer-inner {
    max-width: 1140px;
  }
}
@media only screen and (max-width: 1199.98px) {
  .scroll-btn.container {
    max-width: 920px;
  }

  .footer-inner {
    max-width: 920px;
  }
}
@media only screen and (max-width: 991.98px) {
  .scroll-btn.container {
    max-width: 720px;
    padding: 0 15px;
  }

  .footer-inner {
    max-width: 720px;
  }

  .site-footer .footer-menu > .menu {
    padding-left: 0;
  }
}
@media (max-width: 767.98px) {
  .footer-inner.container {
    padding: 0 15px;
  }

  #site-footer .menu-item:last-child {
    margin-bottom: 25px;
  }
  #site-footer .sign-in {
    margin-bottom: 25px;
  }
  #site-footer #footer-2 {
    margin-bottom: 0;
  }

  #footer_search2 ul {
    margin-bottom: 16px;
  }
  #footer_search2 ul .menu-item:last-child {
    margin-bottom: 0;
  }

  .footer-menu {
    padding-top: 0;
  }
  .footer-menu .menu-item:not(:last-child) {
    margin-bottom: 25px;
  }

  #footer-1.row, #footer-3.row {
    flex-wrap: wrap !important;
  }

  .logocopy {
    margin-bottom: 24px;
    text-align: center;
  }

  #footer-3 .menu {
    padding: 0 0 7px 0;
    margin: 0;
    flex-direction: column;
  }

  .social-icons {
    text-align: center;
  }

  .social-icons .icon:last-child {
    margin-right: 0;
  }
}