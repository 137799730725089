/*  Anupam khosla changes from 22-04-2022 
    I will override previous styles or delete the older ones
    For IE11 to work wordpress generated  inline styles(grid system)
    has to be swapped with flex layouts  
*/





/*global styles start here; no componenets*/
html, body {
  font-size: 1rem;
}
h1, h2, h3, h4, h5, h6 {
  color: $blue;
}
h1 {
  font-size: 2.25rem;     
}
h2 {
  font-size: 30px;  
  font-weight: 900;  
  margin-bottom: 21px;  
}
h3 {
  font-size: 18px;
  font-weight: 700;  
  margin-bottom: 14px; 
}
p {
  font-family: 'Inter', sans-serif;
  font-size: 0.875rem; 
  line-height: 1.5rem;
  color: $dark-blue;        
}
a {
  overflow-wrap: break-word;
  color: $blue;
}
a:not[href] {
  cursor: initial;
}
ul {
  font-size: 14px;
  padding-left: 0;
  // margin-bottom: 20px;
}
ol {
  font-size: 14px;
  // margin-bottom: 20px;
}
button[type='submit'] {
  padding: 12px 30px;
  background-color: #015ba6;
  width: auto;
  min-width: 200px;
  border-radius: 30px;
  color: #fff;
  text-align: center;
  min-height: 0;
  margin-top: 20px;
}

img {
  height: auto;
}

table {
  margin-bottom: 1rem;
}


#app .container div.row {
  flex-wrap: wrap !important; /*overriding iToc app.js*/
}
.page { //fixes margin collapsing
  display: flex;
  flex-direction: column;
}
.entry-content {
  & {
    flex-direction: column;
    display: flex;
  }
  h1 { //Landing pages main title need extra margin; no breadcrumbs
    margin-top: 40px;
  }
}
.page-container .container {
  flex: initial;
  max-width: 1260px;
  padding: 25px 15px 30px;
}

main:not(.inner) > .container {
  padding: 65px 0 20px 0; //total top gap is 90px
}
.page-container .components {
  padding: 50px 0;
}
//#content .container > #post-2841 
#content.inner .container > article { 
  margin: auto;
  max-width: 900px;
  padding: 25px 0 0 0;
}


//main landing pages have big container
#content .container > article#post-2572,
#content .container > article#post-2718 {
  max-width: 1260px;
}


.inner {
  .container {
    max-width: 900px;
  }
  .custom-select {
    margin-bottom: 50px;
  }
  .wp-block-separator.is-style-wide:not(.thin) {
    // margin-top: 70px;
  }
  .gb-block-layout-column-inner h3 {
    font-size: 18px;
    font-weight: 700;
    color: $dark-blue;
  }
}
.text-blue {
  color: $blue;
}
.text-dark-blue {
  color: $dark-blue;
}

/*global styles finish here*/





/*Bootstrap and wordpress overrides*/
//All overrides are exact scss/css nesting as in bootstrap
//bootsrap
@media (min-width: 768px) {  
  .offset-md-half {
    margin-left: 4.16666%;
  }
}
.container .row {
  margin-bottom: 0; 
  //overriding app.js
}
.d-flex2 {
  display: flex;
}
.f-w-600 {
  //custom utility class  
  font-weight: 600;
}
.navbar-nav .nav-link {
  padding: 8px;
}
a.nav-link:focus, a.nav-link:hover { //avoid non anchor links tag
    text-decoration: underline;
}
.navbar-light .navbar-nav .nav-link:focus, 
.navbar-light .navbar-nav .nav-link:hover {
    color: #fff;
}
.navbar-toggler {
  padding: 0;
  margin-left: 15px;
  border-radius: 25px;
}
.navbar-light .navbar-toggler-icon { 
  // background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  // background-size: 20px 20px;
  background-image: none;
  width: auto;
  height: auto;
}
.dropdown-menu {
  margin: 0;
}
.collapsing { //navbar-toggler transition changed to linear, so that opening 2 navs feels like one  
  transition: height .15s linear;
}
.modal-content {
  border: 0;
  border-radius: 0;
}
.modal-header {
  padding: 45px 65px;
  border-bottom: 0;
}
.modal-body {
  padding: 0 65px 85px;
}
.modal-header {
  & {
    align-items: center;
  }
  .close { 
    //exact bootstrap nesting override
    //bootstrap modal close btn
    padding: 0;
    margin: 0;
  }
}
.close:not(:disabled):not(.disabled):focus, .close:not(:disabled):not(.disabled):hover {
  opacity: 1;
  color: $blue;
  text-decoration: underline;
}

.close { //internal close.scss override
  font-size: 14px;
  text-decoration: underline;
  color: $blue;  opacity: 1;  
}

.form-group {
  margin-bottom: 24px;
}
.form-control {
  border-radius: 12px; 
}
.form-control:focus {
  color: $blue;
}
.form-check-input {
  //internal override  
  margin: 0;
  position: static;
}
.dropdown-item:focus, .dropdown-item:hover {
  background-color: transparent;
}


//wordpress
.wp-block-button__link {
  font-size: 14px;
}
.wp-block-image:not(.is-style-rounded) img {
  border-radius: 0;
}
/*overrides finish*/




// block components
//global block component rules; applicable on all pages

//IMPORTANT: internet explorer switch to flexbox  
.ie .gb-layout-column-wrap {
  display: flex;
}
.components { 
  //global block component rules
  //Use parent .component only to override wordpress-gutenberg styles
  //Try to keep css rules as small as possible 

  button { //have to be inside .component to override inline gutenberg style
    //IMP: This might be affecting vuejs buttons too. Check that later when have time
    & {
      border: 0;      
      font-size: 14px;
    }
    &:hover{
      // box-shadow: 0 0 0 4px #ccdeed;
      // transition: box-shadow .2s;
    }
  }

  .wpcf7-form {
    //changing global form styling to specific, so as to avoid styling vuejs itoc stuff
    label {
      color: $blue;
      font-size: 14px;
      margin-bottom: 12px;
    }
    //if oxide content-request form is needed; uncomment input and make sure iToc forms are not affected.
    /* input, */ select {
      border: 1px solid #BCC0C3;
      border-radius: 12px; 
      min-height: 60px;
      color: $blue;
      font-size: 14px;
      font-weight: 500;
    }
    textarea {
      border: 1px solid #BCC0C3;
      border-radius: 12px; 
      width: 100%;
      padding-left: 20px;
    } 

  }
  .non-vuejs { //search page form
    //changing global form styling to specific, so as to avoid styling vuejs itoc stuff
    label {
      color: $blue;
      font-size: 14px;
      margin-bottom: 12px;
    }
    input, select {
      border: 1px solid #BCC0C3;
      border-radius: 12px; 
      min-height: 60px;
      color: $blue;
      font-size: 14px;
      font-weight: 500;
    }
    textarea {
      border: 1px solid #BCC0C3;
      border-radius: 12px; 
      width: 100%;
      padding-left: 20px;
    } 
    button[type='submit'] {
      padding: 20px 30px;
    }
  }

  
  p.desc { //global rule
    font-size: 20px;
    line-height: 34px;    
    color: $dark-blue;  
    margin-bottom: 50px;  
  }
  .nav-list {
    & {
      list-style-type: none;
      padding-left: 0;
      margin: 0
    }
    li {      
      font-weight: 600;
      margin-bottom: 9px;
    }
    li:last-child {
      margin-bottom: 0;
    }
    a {
      text-decoration: none;
      color: $blue;
    }
  }
  .oxide-card {
    & {
      background-color: #e9f7fc;
      padding: 35px 35px 35px 50px;
      border-radius: 30px;
      margin-bottom: 20px;
    }
    & + hr.wp-block-separator.is-style-wide:not(.thin) {
      margin-top: 60px;
    }
    p {
      color: $dark-blue;
      margin-bottom: 8px;
    }
    .gb-block-layout-column-inner {
      position: static; //forcing arrow positioning from bottom
    }
    .gb-container-content > p:last-of-type {
      margin-bottom: 30px;      
    }
    hr.wp-block-separator.thin.is-style-wide {
      margin: 24px 0 22px 0;
    }
    .date {
      font-weight: 600;  
      color: #333;
    }
  }
  
  .pill-download {
    & {
      display: block;  
      margin-bottom: 15px;    
    }
    &:last-child {
      margin-bottom: 0;
    }
    .gb-block-layout-column-gap-2 {
      display: flex;
      grid-gap: 0 10px;
      align-items: center;
    }
    .gb-block-layout-column {
      width: auto;
    }
    .wp-block-column {
      flex: 0 0 auto;
    }
    a {
      color: $dark-blue;
    }
    h3 {
      margin-bottom: 0;
    }
    p {      
      font-weight: 600;      
      color: $blue;
      margin-bottom: 0;
    }
    .wp-block-button__link {
      display: inline-flex;
      line-height: 24px;
      color: $dark-blue;
      background-color: $light-blue;
      padding: 3px 16px;
      font-size: 14px;
      border-radius: 8px;
    }
    .wp-block-button {
      margin-bottom: 0;
    }
  }
  
  article .pill-img {
    & {
      padding: 21px 33px;
      border-radius: 30px;
      background-color: #E9EEF1;
      margin-bottom: 30px;
    }
    figure {
      margin: 0;
      width: 83px;  
      height: 83px;  
      background-color: transparent;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    img {
      border-radius: unset;
    }
    h3 {
      margin-bottom: 14px;
    }
    p {
      font-size: 18px;
      line-height: 28px;
    }
    .gb-layout-column-wrap {
      grid-template-columns: 83px 1fr;
      grid-gap: 36px;
    }
    .gb-block-layout-column {
      display: inline-grid;
      align-content: center;
    }
  }

  article .pill-yellow {
    background-color: #FEEDDA;
  }
  article .pill-blue {
    background-color: #EDEAF3;
  }
  article .pill-cyan {
    background-color: #E3F4F5;
  }
  article .pill-green {
    background-color: #EAF6F1
  }
  article .pill-pink {
    background-color: #FADEE2;
  }

  .custom-select {
    border-radius: 12px;
    border: 1px solid #BCC0C3;
    width: 350px;
    height: 60px;
    font-size: 14px;
    font-weight: 500;
    padding-left: 20px;
    color: $blue;
    background: url("../icons/select_down.png") no-repeat right 25px center;    
    text-align: left;
  }
  .pagination-basic {
    & {
      color: $blue;
      font-weight: 500;
      margin-bottom: 20px;
    }
    a {
      margin-left: 70px;
      text-decoration: underline;
    }
  }
  .three-card-item {
    & {
      justify-content: flex-start;
    }
    h3 {
      margin-bottom: 15px;  
    }
  }
  .filter-category {
    margin-bottom: 10px;
  }
  .dynamic-page-count { //this appears on paginated pages/posts
    color: $blue;
    font-weight: 600;
  }
  .no-res-msg { //search form message
    margin-top: 30px;
  }
  .pagination {
    & {
      margin-top: 28px;
      padding: 0 15px;
      width: 100%;
    }
    p {
      margin: 0;
    }
    .gb-is-responsive-column {
      display: flex;
    }
    .gb-block-layout-column {
      display: flex;
      align-items: center;
      width: auto;
    }
    .gb-block-layout-column-gap-2 {
      & {
        grid-gap: 0 60px;
      }
      .gb-block-layout-column-gap-2 { //level2 cols
        grid-gap: 0 20px;
      }
    }
    .gb-layout-columns-2>.gb-layout-column-wrap {
      grid-template-columns: none;
    }
    .wp-block-buttons {
      column-gap: 15px;
    }
    .wp-block-button {
      margin-bottom: 0;
    }    
    .wp-block-button__link:active,
    .wp-block-button__link:focus,
    .wp-block-button__link:hover,
    .wp-block-button__link:visited {
      color: #fff;
    }
    .wp-block-button__link {
      & {
        font-size: 14px;
        padding: 16px 30px 17px 20px; //intentional 1px extra at bottom
        background-color: $blue;
        color: #fff;
      }
      img {
        position: relative;
        top: -1px;     
        margin: 0 10px 0 0;  
        transform: scale(-1); /*inverting arrow*/  
      }
    }
    .wp-block-button:nth-child(2) .wp-block-button__link {
      & {        
        color: #fff;
        padding: 16px 33px 17px 43px; //intentional 1px extra at bottom
      }     
      img {
        margin: 0 0px 0 10px;
        transform: scale(1);
      } 
    }
    .wp-block-button.disable-if-empty a:not([href]) {
      & {
        color: #B0B0B0;
        background-color: #f2f2f2;
      }
      img {
        filter: brightness(0.7);
      }
    }

  }
  .oxide-pagination {
    width: auto;
    padding-right: 49px;
    margin-bottom: 0;
    display: none; 
    //IMPORTANT: Anupam: disabled because php work is out of scope of contract.
    // This feature requires more php work and since the dev site is html based, it actually wouldn't work there at all.
  }

  & > div[class*="wp-block-"] { 
    margin-bottom: 100px;
  }  
  .link .wp-block-button__link { 
    font-size: 14px;
    font-weight: 700;
    text-decoration: underline;
  }
  .wp-block-button__link:active,
  .wp-block-button__link:focus,
  .wp-block-button__link:hover,
  .wp-block-button__link:visited {
    color: $blue;
  }
  .wp-block-button__link {
    background-color: transparent;
    padding: 0;
    display: inline;
  }
  .wp-block-embed {
    margin-bottom: 0;
  }
  .wp-block-separator:not(.thin) {
    width: 110px;
    height: 11px;      
    border-radius: 40px;
    background-color: $light-blue;
    border-style: none;
    opacity: 1;
    margin: 90px 0 50px 0;
  }
  .d-flex p + .wp-block-separator:not(.thin) { // only if margin collapsing is disabled
    margin: 74px 0 50px 0; //margin top 90-16; 16 from p tag 
  }
  hr.wp-block-separator.thin.is-style-wide {
    width: 100%;
    border-bottom-color: $gray-hr;
    opacity: 1;  
    margin: 16px 0 32px; //override rem margin  
  }
  .uct_cards + .wp-block-separator:not(.thin) {
    margin: 70px 0 50px 0;
  }


  .wp-block-separator.less_gap {

  }
  .gb-block-layout-column-gap-2 {
    grid-gap: 0 30px;
  }  
  .gb-2-col-wideright>.gb-layout-column-wrap {
    grid-template-columns: 26fr 60fr;
  }
}


//components without parent class
.btn-pm {
  .wp-block-button__link {
    background-color: $blue;  
    padding: 14px 19px 14px 25px;
    color: white;
    display: block;
  }
  .wp-block-button__link:active,
  .wp-block-button__link:focus,
  .wp-block-button__link:hover,
  .wp-block-button__link:visited {
    color: white; 
  }
}
.about-ul {
  color: $dark-blue;
}
.wp-block-embed-youtube figcaption a { //homepage video
  color: $blue;
  font-weight: 700;
  text-decoration: underline;
}
.wp-block-embed figcaption {
  margin-top: 1rem;
}
.link-underlined a {
  font-weight: 700;
  text-decoration: underline; 
}
.long-pill-columns {
  & {
    margin-bottom: 34px;
  }
  .gb-block-layout-column-gap-2 {
    grid-template-columns: 900fr 290fr;
  }
  .gb-block-layout-column:nth-child(2) {
    padding-left: 40px; 
  }
}
.pill-lg-btn {
  & {
    width: 100%;
  }  
  .wp-block-button__link {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 30px;
    background-color: $light-blue;  
    font-weight: 600;
    padding: 25px 30px 25px 40px;
    border-radius: 30px;
  }
  img {    

  }
}
.pill-long { //global
  & {
    background-color: $light-blue;
    padding: 20px 30px;
    margin-bottom: 30px;
    border-radius: 30px;
  }
  & > .gb-block-layout-column-gap-2 {
    grid-gap: 30px;
  }
  &.gb-layout-columns-2 > .gb-layout-column-wrap {
    grid-template-columns: 76fr 24fr;
    align-items: center;
  }  
  p {
    & {
      position: relative; 
      margin: 0 0 0 80px;   
      display: flex;
      align-items: center;
      font-size: 20px;
      min-height: 46px;
    }
    img {
      left: -55px;
      position: absolute;
      transform: translateX(-50%);
    }
  }
  p::before {
    content: "";
    position: absolute;
    width: 50px;
    height: 50px;
    background-color: $success;
    z-index: -1;
    border-radius: 50%;
    left: -80px;
  }

  &:nth-of-type(odd) p::before {
    background-color: $blue;
  }

  .wp-block-button {
    & {
      margin-bottom: 0;
    }    
  }
}
.check-list {
  & {

  }
  img {
    align-self: flex-start;
    margin: 4px 20px 0 0;
  }
  li {
    display: flex;
    color: $dark-blue;
    font-size: 18px;
    align-items: flex-start;
    margin-bottom: 7px;
  }
}
.border-list {
  & {
    list-style-type: none;
    margin-bottom: 83px;
    padding-left: 0;
    counter-reset: section; 
    /* flex box list item bug: https://stackoverflow.com/q/25942417/3429430 */
  }
  li {
    min-height: 60px;
    border-bottom: 1px solid #D6D6D6;
    color: $dark-blue;
    padding: 10px 0 10px 40px;
    display: flex;
    align-items: center;
  }
  li:before {
    counter-increment: section;
    content: counter(section) "";
    position: absolute;
    margin-left: -40px;
  }
}  

.wp-block-table table {
  & {
    font-size: 14px;
    border-bottom: none;
  }
  tr:not(:first-child) td {
    vertical-align: middle;
  }    
  td {
    color: $dark-blue;
    padding: 6px 0 0 0; //6px to match inline style by gutenberg 
    /*gutenberg editor puts inline css with important. 
    Other solution is to dequeue that in functions.php*/
    height: 60px;
    border-bottom: 1px solid #D6D6D6;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    line-height: 25px;
  }
  
}
.wp-block-table { //second table; but global
  thead {
    & {
      border: 0;
    }
    th {
      color: $dark-blue;
      font-weight: 700;
      border: none;
      height: 60px;
      padding: 0;
    }
  }
}
.table-grand-container {
  & {
    overflow-x: auto;
  }
  &.two-col .table-container  {
    min-width: 690px;
  }  
  & .two-col .wp-block-column:first-child th {
    text-align: left;
    vertical-align: top;
  }
  & .two-col th {
    text-align: center;
    vertical-align: top;
  }
}
.table-container {
  & {
    min-width: 870px;
    display: grid;
    column-gap: 20px;
    grid-template-columns: 200fr 320fr 320fr; 
    margin-bottom: 20px;
    transition: opacity .3s ease;
  }
  &.two-col {
    grid-template-columns: 2fr 1fr; 
  }
  .title2 {
    & {
      margin-bottom: 0;
    }
    td {
      border-bottom: 0;
      font-weight: 700;
      color: $blue;      
    }      
  }
  .wp-block-column:not(:first-child) {
    margin-left: 0;
  }
  .is-style-stripes tbody tr:nth-child(odd) {
    background-color: transparent;
  }
  .inner td {
    width: 100px;
    text-align: center;
    color: $blue;
  }
  figure:nth-child(2) tr:first-child td {
    position: relative;
    color: $blue;

  }
  figure:nth-child(2) tr:first-child td:before {    
    top: 10px;
    content: "▲";
    position: absolute;
    display: block;
    opacity: .25;
    right: 0px;
    line-height: 9px;
    font-size: .8em;
    z-index: 2;
  }
  figure:nth-child(2) tr:first-child td:after {    
    content: "▼";
    position: absolute;
    display: block;
    opacity: .25;
    top: 19px;
    right: 0px;
    line-height: 9px;
    font-size: .8em;
    z-index: 2;
  }
  figure:nth-child(2) tr:first-child td.asc:before {
    opacity: 0.6;
  }
  figure:nth-child(2) tr:first-child td.desc:after {
    opacity: 0.6;
  }
  .wp-block-column:not(:nth-child(1)) figure:nth-child(2) tr:first-child td:nth-child(1):before,
  .wp-block-column:not(:nth-child(1)) figure:nth-child(2) tr:first-child td:nth-child(1):after,
  {
    right: -3px;
  }
  figure:nth-child(2) tr:first-child td:nth-child(2) {
    left: 5px;
  }
  figure:nth-child(2) tr:first-child td:nth-child(2):before,
  figure:nth-child(2) tr:first-child td:nth-child(2):after {
    right: -10px;
  } 
  
}

.ref, .ref-multi {
  /*IMPORTANT Assuming dynamic content in these two tables, it is not possible to ensure border-bottoms match for each line.
  Easisest solution is to use ul>li and then reorder even li's at the bottom with flex-order  
  This improvement can me made in later versions of the project*/
  & > table {
    margin-bottom: 16px;
    min-width: 690px;
  }  
  &.wp-block-table table tr:not(:first-child) td,
  &.wp-block-table tr td {
    height: auto;
    vertical-align: top;
    padding-top: 10px !important; /*overriding gutenberg inline styles*/
    padding-bottom: 10px !important;
    width: 50%;
  }
  
  th:first-child, td:first-child {
    padding-right: 30px;
  }
  th:last-child, td:last-child {
    padding-left: 30px;
  }  
}

.ref-multi {  
  &.wp-block-table table tr:not(:first-child) td,
  &.wp-block-table tr td {
    width: auto;
  }
  &.wp-block-table th, &.wp-block-table td {
    padding-right: 30px;
  }
  th:last-child, td:last-child {
    padding-left: 0;
  }  
}



.caption-img {
  & {
    margin-top: 90px;
    display: flex;
    flex-direction: column;
    row-gap: 38px;
    align-items: center;
    background-color: #f9f9f9;
    padding-top: 38px;
    border-radius: 30px;
    overflow: hidden;
  }
  img {
    padding: 0 10px;
  }
  figcaption {
    width: 100%;
    background-color: $dark-blue;
    margin: 0;
    padding: 40px 67px 44px;
    font-size: 14px;
    color: #fff;
  }
}
.banner_image.reverse {
  & {
    flex-direction: row-reverse;
  }
  .wp-block-media-text__content {  
    & {
      padding: 50px 0 0 0;
    }
    p {
      color: $dark-blue;  
      font-size: 1.25rem;
    }
  }
  .wp-block-media-text__media {
    & {
      padding: 0 0 0 122px;
    }
  }
  .wp-block-media-text__media::after {
    left: 86px; 
  }
}
.wp-block-image.regular {  
  figcaption {
    text-align: center;
    color: $dark-blue;
    font-size: 14px;
    margin: 10px 0 0 17px;
  }
}
.snomed-heading {
  margin-bottom: 50px;
}
.uct_cards {
  & {
    
  }
  .three-card-item {
    & {
      word-break: normal;
      padding: 32px 60px 75px 40px;
      position: relative;
      justify-content: flex-start;
    }      
    p {
      margin: 0;
    }
    /* fontawesome arrow icon
    .wp-block-button:before {
      content: "\f061"
    }
    */
    .wp-block-button {
      display: flex;
      color: $blue;
      position: absolute;
      bottom: 29px; /*6px extra from figma*/
      right: 34px; /*1px extra from figma*/
      margin-bottom: 0;
    }
  }  
}
.uct_two_block {
  & {
    margin: -20px 0 0px 0;
  }
  h2 {
    margin-bottom: 30px;    
  }
  h3 {
    margin-bottom: 12px;
  }
  p {
    margin-bottom: 20px;
  }
  .gb-block-layout-column {
    padding-right: 100px;
  }
  .gb-block-layout-column.invisible {
    display: none;
  }
}
.contact-card {
  & {
    background-color: $light-blue;
    border-radius: 30px;
    padding: 40px 40px 50px;
    margin-top: 90px;
  }
  figure {
    & {
      background-color: $blue;
    }
    img {
      width: 20px;
      height: auto;
    }
  }
  h2 {
    line-height: 43px;
    margin-bottom: 20px;
  }
  p {
    font-weight: 500;
    line-height: 25px;
    margin-bottom: 0;
  }
  .gb-block-layout-column:nth-child(2) {
    align-self: flex-end;
  }
  .wp-block-media-text {
    display: flex;
    margin-bottom: 25px;
  }
  .wp-block-media-text:nth-child(2) {
    margin-bottom: 0;
  }
  .wp-block-media-text__media {
    border-radius: 50%;
    padding: 15px;
    width: 50px;
    height: 50px;
    background-color: $blue;     
    display: flex;
  }
  .wp-block-media-text__content {
    & {
      padding: 0;
    }
    p {
      padding-left: 15px;        
      line-height: 27px;
    }
  }
}
.dynamic-latest-posts {
  & > li {
    background-color: $light-blue;
    padding: 40px 60px 40px 40px;
    display: grid;
    border-radius: 30px;
  }
  time {
    order: 2;
  }
}
.wpcf7-form { //contact form 7
  p {
    display: flex;
    flex-wrap: wrap;
    color: $blue;
    column-gap: 10px;
    margin-bottom: 30px;
  }
  p.text-dark-blue {
    color: $dark-blue;
  }
  p a {
    text-decoration: underline;
  }
  label {
    font-weight: 500;
    flex: 1 1 auto;
  }
  .wpcf7-form-control-wrap {
    display: flex;
    margin-top: 10px;
  } 
  input {    
    min-width: 440px;
    padding-left: 20px;
    width: 100%;
  }
  input[type='checkbox'] {
    min-width: 0;
    width: auto;
    min-height: 0;
  }
  label select.custom-select {
    margin-bottom: 0;
    width: 440px;
  }
  .msg {
    width: 100%;   
  }
  .wpcf7-submit {
    padding: 12px 30px;
    background-color: $blue;
    width: auto;
    min-width: 200px;
    border-radius: 30px;
    color: #fff;
    text-align: center;
    min-height: 0;
    margin-top: 20px;
  }
} 


//iToc vue js specific styles  

*[vue] {
  table {   
    & {
      width: 100%;
    }
    td, th {
        border-left: 1px solid $gray-hr;
        border-right: 1px solid $gray-hr;
        padding: 0.85em 0.75em;
        border-bottom: 1px solid $gray-hr;
    }
    tr:first-child td {
        border-top: 1px solid $gray-hr;
    }    
  }  
  .v-menu__content {
    background-color: white;    
  }  
}

.elevation-1 {
  .markdown p {
    font-size: 0.875rem !important;
  }
  .v-btn .v-btn__content .v-icon {
    color: white;
  }
}

h2.text-xs-center + .container + .elevation-1:not(article .elevation-1) {
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 15px;
}

#app .v-card__title.fixed {
  z-index: 100; //less than oxide navigation
}

@media only screen and (min-width: 1200px) {
  //make iToc vueJs tables bigger than oxide containers for desktop only  
  h2.text-xs-center + .container + .elevation-1 {
    margin: 0 -150px;
  }
  *[vue] {
    .v-menu__content {
      background-color: white;
      transform: translateX(calc(-45vw + 600px));
    }  
  }
}

//global block rules finish




// Single post page rules  
.post-header {
  background-color: $light-blue;
  padding: 70px 70px 50px;
  border-radius: 30px;
  margin-bottom: 50px;
}
.post-title {
  margin-bottom: 22px;
}
.post-date {
  margin-bottom: 24px;
}
.post-author {
  color: $blue;
}
.type-post {
  .yoast-breadcrumbs {
    & {
      font-size: 14px;
    }
    a {

    }
    span {
      color: $dark-blue;
    }
  }
  p {    

  }
  .main-para {
    font-size: 20px;
    line-height: 31px;
    margin-bottom: 12px;
  }
  & > h2 {
    margin-bottom: 21px;
    line-height: 43px;
  }
  & > h3 {
    margin-bottom: 14px;
  }
  hr.is-style-wide {
    margin-top: 82px;
  }
  .uct_cards {
    & {
      margin-top: 50px;
    }
    .three-card-item {      
      p {
        color: #333;
        margin-bottom: 15px;
      } 
      p:last-of-type {
        font-weight: 600;
        margin-bottom: 0;
      } 
    }
    
  }
}



//specific pages below
//Front page //homepage
.home {
  h1 {
    margin-bottom: 40px;
  }
  h2 {
    margin-bottom: 24px;
  }
}
.banner_image {  
  & {
    display: flex; 
    justify-content: space-between; 
  }
  h1 {
    margin-bottom: 30px;
  }
  .wp-block-media-text__media {
    flex: 1 1 0;
    padding: 0 50px 0 0;
    align-self: flex-start;
    position: relative;     
    & {
      img {
        max-width: 544px;
        border-radius: 20px;
        min-height: 366px;
        object-fit: cover;
        object-position: center
      }
    } 
    &::after {
      content: "";  
      display: block;            
      background-color: $light-blue;
      position: absolute;
      top: 67px;
      left: 0;
      bottom: 0;
      right: 50px;
      z-index: -1;
      border-radius: 30px;
    }
  }
  .wp-block-media-text__content {    
    & {
      flex: 1 1 0;
      padding: 50px 0 0 50px;
      align-self: flex-start;
    }
    .wp-block-buttons {
      margin-top: 40px;
      column-gap: 20px;
    }    
    & > .wp-block-media-text__media {
        /*this will shown in mobile view; can't change html - gutenburg generated*/
        display: none;        
      }
    }
    .wp-block-button__link {
      background-color: $blue;
      color: #fff;
      font-size: 0.875rem;
      text-decoration: none;
      font-weight: 500;
      min-width: 240px;
      min-height: 50px; 
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }
  }

  .video_info {
    & {
      background-color: $light-blue;  
      padding: 40px 60px 80px 80px;
      border-radius: 30px;
      margin-top: 90px;
    }
    .wp-block-columns {
      margin-bottom: 0;
    }
    .wp-block-button__link {
      color: $blue;
      font-weight: 700;
    }
  }

  .oxide-card {
    & {
      margin-bottom: 20px;
    }
    .gb-block-layout-column-inner p {
      margin-bottom: 30px;
    }
    .pill-download p {
      margin-bottom: 0;
    }
    .wp-block-button.btn-pm {
      position: static;
      display: flex;
      color: #fff;
    }
    .btn-pm img {
      margin-left: 12px;
      margin-top: -2px;
    }
  }

  .two-cols {
    h3 {
      color: $dark-blue;
    }
    .gb-block-layout-column-gap-2 {
      grid-gap: 0 20px;
    }
    .gb-block-layout-column-inner p {
      margin-bottom: 0;
    }
  }
  

//font page finishes


.fy { //finincial year page
  
  p.desc {
    color: $dark-blue;    
    line-height: 34px;
    margin-bottom: 50px;
  }
  p.select-para {
    color: $blue;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 30px;
  }  
  .custom-select {
    margin-bottom: 80px;
  }
  .custom-select.dropdown-toggle::after {
    display: none;
  }
  .dropdown-menu {
    min-width: 350px
  }
  .col-block {

  }
  .gb-layout-column-wrap {
    display: flex;
    column-gap: 20px;
  }
  .gb-block-layout-column-inner {
    & {
      background-color: $light-blue;
      padding: 46px 40px 56px;
      border-radius: 30px;
      margin-bottom: 20px;
      width: 100%;
      min-height: 202px;
    }
    & > h5 {
      font-weight: 700;
      color: $dark-blue;
      font-size: 18px;
    }
    & > p {
      float: left;
      font-size: 48px;
      line-height: 60px;
      margin-right: 20px;
      padding-left: 8px;
      color: $blue;
    }
    .wp-block-button__link {
      & {
        background-color: $success;
        display: flex;
        column-gap: 7px;
        align-items: center;
        justify-content: space-between;
        min-width: 80px;
        padding: 5px 10px;
        color: #fff;
        border-radius: 10px;
        cursor: auto;
      }
      & > img {
        width: 21px;
        height: 15px;
      }
    }
    .wp-block-button {
      margin-bottom: 0;
      margin-top: 15px;
    }
  }
  p.update {
    margin-top: 40px;
    color: $blue;
  }  
}


//amt page  
#post-2841 { //amt page id
  & {
    color: $dark-blue;
  }
  h2 {
    margin-bottom: 30px;  
  }
  p {
    color: $dark-blue;
  }
  li {
    
  }
  // .wp-block-button__link {
  //   padding: 13px 27px;
  //   background-color: $blue;
  //   text-decoration: none;
  //   color: #fff;
  //   font-weight: 500;
  // }
  // .uct_cards .wp-block-button__link {
  //   padding: 0;
  //   background-color: transparent;
  //   text-decoration: none;
  //   color: $blue;
  //   font-weight: 700;
  // }
  
  #about-the-amt ~
  .about-ul {
    & {
      margin-bottom: 83px;
    }
    li {
      line-height: 25px;
    }
  }
  .wp-block-image.regular {
    & {
      margin: 75px 0 30px 0;
    }
    figcaption {
      text-align: center;
      color: $dark-blue;
      font-size: 14px;
      margin: 10px 0 0 17px;
    }
  }
  #take-a-look + .wp-block-buttons {
    & {
      margin-bottom: 20px;
    }
    .wp-block-button {
      margin: 0;
    }
    .wp-block-button__link {
      padding: 13px 36px;
      display: flex;
      column-gap: 16px;      
    }
    img {
      margin-bottom: 2px;
    }
  }
  #highlights {
    
  }
  .heading-ol {
    margin: 74px 0 22px;
  }
  

  #who-use-snomed {
    margin-bottom: 70px;
  }
 
  .text-button-block {
    & {
      align-items: center;
      margin-top: 5px;
    }
    &.wp-block-columns {
      margin-bottom: 20px; 
    }
    .wp-block-column {
      min-width: auto;
    }
    .wp-block-column:first-child {
      flex: 0 0 auto;
      
    }
    .wp-block-column:not(:first-child) {
      margin: 0;
    }
    p {
      margin-bottom: 0;
      font-weight: 600;
      margin-right: 10px;
    }
  }  
  .wp-block-buttons .download { 
    margin-bottom: 0;
  }
  .download {
    .wp-block-button__link {
      display: inline-flex;
      line-height: 18px;
      color: $dark-blue;
      background-color: $light-blue;
      padding: 6px 10px;
    }
  } 
  .uct_cards {
    p {
      font-size: 18px;
      line-height: 24px;
      font-weight: 700;
      color: $dark-blue;
    }
  }  
} 


//Understanding page  
/*All rules inherited from components*/


//Recent-updates page  
#post-3099 {
  .desc {
    margin-bottom: 0;
  }
  h2 {
    margin-bottom: 38px;
  }
  h3 {
    color: $blue;
  }  
  .uct_cards .three-card-item {
    padding: 30px 30px 50px;
  }
}


//contact and support page  
.three-card-block .three-card-item.empty { //sort of global rule
  visibility: hidden;
  opacity: 0;
}
.banner_image.contact-page {
  & {
    margin-bottom: 60px;
  }
  .wp-block-media-text__content p {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 26px;
  }
  ul {
    font-size: 18px;
    color: $dark-blue;
  }
  ul > li {
    margin-bottom: 8px;
  }
  li > img {
    margin-right: 20px;
    position: relative;
    bottom: 1px;
  }
}



//news and events landing page
.news-events { 
  h1 {
    margin-bottom: 40px; 
  } 
  p.desc {    
    line-height: 34px;
    margin-bottom: 65px;
    color: $dark-blue;
    max-width: 900px; //container on inner pages size
  }
  .post-date {
    font-size: 14px;
    font-weight: 600;
    color: #333;
    margin-top: 20px;
  }
  .uct_cards {
    grid-gap: 0 0;
  }
  .three-card-item {
    height: 328px;
    justify-content: flex-start;
    overflow: auto;
    padding: 32px 60px 0 40px
  }
  .news-col {
    margin-bottom: 30px;
  }
}



//Licencing faq page inner 
.inner {
  .entry-title {
    margin-bottom: 40px;
  } 
  .yoast-breadcrumbs {
    & {
      order: -1;
      margin-bottom: 40px;
      font-size: 14px;
    }
    span {
      color: $dark-blue; 
    }
    a {
      color: $blue;
      font-weight: 400;
    }
  }
}




// glossary inner page  
.page-id-2687 { //glossary page id, change this to "inner" if doesn't work dev server
  h2 {
    margin-bottom: 50px;
  }  
  p.desc {
   margin-bottom: 0;
  }  
  .wp-block-genesis-blocks-gb-column p {
    color: $blue;
    font-weight: 600;
  }
  .wp-block-genesis-blocks-gb-column:nth-child(2) p {
    color: $dark-blue;
    font-weight: 400;
  }
  .gb-layout-columns-2 + hr.wp-block-separator.is-style-wide:not(.thin) {
      margin: 76px 0 50px; 
  }
}



//Article page  
/*All styles inhertid from globals and components*/



//search page  and results page
.search { //search paghe body tag class
  .page-header {
    margin-bottom: 0;
  }
  .custom-select {
    width: 100%;
  }
  .dynamic-page-count {
    margin-top: 66px;
    font-weight: 600;
  }
}



//content-rquest page  
#post-3206 {
  .wpcf7 {
    & {
      margin-top: 40px;
    }
  }
}



//Resources page  
#post-2672 {
  h2 {
    margin-bottom: 30px;
  }
  .desc {
    margin-bottom: 60px;
    //to be discussed with Renae
  }
}


//Licensing page  
#post-3057 { //page id -- specific rules
  
  #more-info ~ p:last-of-type {
    margin-bottom: 30px;
  }  
  .pill-download .wp-block-button__link {
    background-color: #fff;
  }
}


//sorta global
.gb-block-accordion + .gb-block-accordion {
  margin: -2px 0 00px 0;
}
//sorta global
.gb-block-accordion { 
  & {
    margin-bottom: 0;  
  } 
  & > details {    
    padding: 35px 50px 35px 30px;
    border-radius: 30px;
    position: relative;
  }
  & > details[open] {
    background-color: $light-blue;
    
    border-radius: 30px;
    margin-bottom: 10px;
  }  
  & > details:not([open])::after {
    content: " ";
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: $light-blue;
    bottom: 0;
  }
  .gb-accordion-title {
    background-color: transparent;
    padding: 0 0 0 40px;
    list-style-type: none;
    position: relative;
    color: $blue;
    font-weight: 600;
  }
  .gb-accordion-title:before {
    content: url("../icons/arrow_details.svg");
    position: absolute;
    left: 0;       
  }
  details[open] .gb-accordion-title:before {
    transform: rotateZ(-180deg);
    left: 0;
    top: -6px;
  } 
  .gb-accordion-text {
    padding: 30px 0 0 0;
    color: $dark-blue;
  }
  .gb-accordion-text > * { //tricky, other solutions cause blue border moving issue.
    padding: 0 0 0 40px;
  }
  .gb-accordion-text > ul,
  .gb-accordion-text > ol { 
    padding: 0 0 0 55px;
  }
}
#post-2677 {  //licencing page id
  .entry-title {
    margin-bottom: 20px;
  }
}




//contact-us level2 page  
.contact-icons {
  & {
    margin-bottom: 0;
  }
  .wp-block-media-text {
    
  }
  .wp-block-media-text__media  {
    border-radius: 50%;
    padding: 15px;
    width: 50px;
    height: 50px;
    background-color: $blue;
    display: flex;
  }
  .wp-block-media-text__content {
    & {
      padding: 0;
    }
    p {
      font-size: 18px;
      padding-left: 15px;
      line-height: 34px;
      margin: 0;
    }
  }
  & ~ .wp-block-group { //siblnig selector 
    h2 {
      margin-bottom: 40px;
    }
    .gb-block-layout-column-inner {
      p {
        color: $dark-blue;
        line-height: 30px;
        margin-bottom: 0;
      }
      a {
        color: $blue;
        font-weight: 400;
        text-decoration: none;
      }
    }
  }
  

}






//only screen to print same page on save-as-pdf
@media only screen and (max-width: 1399.98px) {
  .page-container .container { 
    max-width: 1140px;
  }  
  .inner .container { /*inner pages only*/
    max-width: 900px;    
  }
  .banner_image { 
    .wp-block-media-text__media {
      padding: 0 76px 0 0;
      &:after {
        right: 40px;
      }
    }
    .wp-block-media-text__content {
      padding: 40px 0 0 40px;
    }

  }
}

//only screen to print same page on save-as-pdf
//this mq matches bootstrap 4.6 query
@media only screen and (max-width: 1199.98px) {

  //global rules 
  .page-container .container { 
    max-width: 920px;
  }

  .inner .container { /*inner pages only*/
    max-width: 900px;    
  }
  
  // global rules finish  



  //components
  .banner_image {
    .wp-block-media-text__media {
      padding: 0 76px 0 0;
      &:after {
        right: 35px;
      }
    }
    .wp-block-media-text__content {
      padding: 40px 0 0 30px;
    }
    .wp-block-button__link {         
      min-height: 40px;   
      margin-bottom: 10px;         
    }
  }  
  .uct_cards .three-card-item {
    padding: 30px 40px 75px 30px
  }
}

//only screen to print same page on save-as-pdf
//this mq matches bootstrap 4.6 query
@media only screen and (max-width: 991.98px) {
  //global rules 
  .entry-content h1 {
    margin-top: 0;
  }
  .gb-layout-columns-3 > .gb-layout-column-wrap.gb-is-responsive-column {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    grid-template-areas:
        "col1"
        "col2"
        "col3";
  }
  .page-container {
    .container { 
      max-width: 720px;
    }  
    .components {
      padding: 50px 30px;
    }
  }
  
  // global rules finish  

  .components {    
    .wp-block-button__link {
      // min-width: 240px;
    }
    .wp-block-separator:not(.thin) {
      margin: 70px 0 30px 0;
    }
    .pagination {
      & {
        justify-content: center;
      }
      .gb-block-layout-column {
        justify-content: center;
      }
      & > 
      .gb-block-layout-column-gap-2 {
        flex-direction: column;
        row-gap: 30px;
      }
      .wp-block-buttons {
        row-gap: 24px;
      }
      .gb-layout-columns-2 > .gb-layout-column-wrap {
        flex-wrap: wrap;
        row-gap: 10px;
        justify-content: center;
      }
    }       
    .uct_cards + .wp-block-separator:not(.thin) {
      margin: 50px 0 30px 0;
    }
  }  
  .pill-long.gb-layout-columns-2 > .gb-layout-column-wrap {
    grid-template-columns: 65fr 24fr;
  }
  



  .banner_image {
    & > .wp-block-media-text__media {
      display: none;
    }
    .wp-block-media-text__content {  
      padding: 0;    
      & > .wp-block-media-text__media {
        display: block;
        /*this will shown in mobile view; can't change html - gutenburg generated*/
        margin: 30px 0;
      }
      & .wp-block-buttons {
        margin-top: 30px;
      }
    }
    .wp-block-media-text__media {
      & {
        padding: 0 36px 0 0;
      }
      &:after {
        right: 0;
      }
      img {
        max-width: none;  
        min-height: 0;
      }      
    }
  }
  .banner_image.reverse {
    .wp-block-media-text__content {  
      padding: 0;        
    }
    .wp-block-media-text__media {
      & {
        padding: 0 0 0 36px;
      }
    }
    .wp-block-media-text__media::after {
      left: 0;
      right: 35px;
    }
  }
  .video_info {
    margin-top: 45px;
    padding: 30px 45px 60px 60px;
  }

  .wp-block-columns { //global rule
    margin-bottom: 0;
  }

  .three-card-block { //global for all cols
    & {
      flex-direction: column;
      grid-gap: 0;
    }
    .three-card-item {
      & {
        margin-bottom: 20px;
      }
      &.invisible {
        & {
          padding: 0;
          margin: 0;
          height: 0;
        }
        h3 {
          margin: 0;
        }
      }
    }

    .three-card-item.empty {
      display: none;
    }
  }

  .contact-card {
    h2 {
      line-height: 1.2;
    }
  }

  .news-col {
    .three-card-item {
      margin-bottom: 0;
      height: auto;
      padding: 32px 60px 75px 40px;
    }   
  }



  .fy { //finincial year page
    .gb-block-layout-column-inner {
      min-height: 202px;
    }
  }



  #post-2841 { //amt page id
    // .table-container {
    //   grid-template-columns: 158px 231px 231px;
    //   overflow-x: auto; 
    // }
  }

  //contact-us page
  .contact-icons {
    & + .wp-block-separator:not(.thin) {
      margin: 70px 0 50px 0;
    }
    & ~ .wp-block-group {
      
      .gb-block-layout-column {
        margin-bottom: 10px;
      }
    }
    
  }
  

}

@media only screen and (max-width: 767.98px) { 
  //global base css  
  h1 {
    margin-bottom: 1rem;
    font-size: 1.75rem;
  }
  h2 {
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    line-height: 1.4;
  }
  .page-container {    
    .components { 
      padding: 30px;
    }  
  }
  main#content {
    padding: 0 15px;
  }
  .page-template-template-page-builder-php #content {
    //wordpress override  
    padding: 0 15px !important;
  }
  main:not(.inner) > .container {
    padding: 30px 15px 30px; 
  }
  main.inner > .container {
    padding: 25px 15px 30px; 
  }
  .inner .yoast-breadcrumbs {
    margin-bottom: 20px;
  }
  .inner .entry-title {
    margin-bottom: 1rem;
  }


  /*Bootstrap wordpress overrides*/
  .gb-layout-columns-2>.gb-layout-column-wrap.gb-is-responsive-column {    
    grid-template-columns: 1fr;
    grid-template-areas:
        "col1"
        "col2";
  }
  





  //components
  .components {
    h2 {
      font-size: 1.5rem;
    }
    p.desc { 
      font-size: 1rem;
      line-height: 1.5;
      margin-bottom: 30px;
    }
    .gb-2-col-wideright>.gb-layout-column-wrap {
      grid-template-columns: 1fr;
    }
    .wp-block-separator:not(.thin) {
      margin: 50px 0 25px 0;
    }
    .d-flex p + .wp-block-separator:not(.thin) {
      margin: 34px 0 25px 0; // 50-16
    }
    .uct_cards + .wp-block-separator:not(.thin) { 
      margin: 30px 0 25px 0; 
    }
    .uct_cards {
      .three-card-item {
        & {
          padding: 30px 20px 50px 20px;
        }
        &.invisible {
          padding: 0;
        }
        .wp-block-button {
          bottom: 10px;
        }
      }
    }
    .three-card-item {      
      h3 {
        line-height: 1.2;
        margin-bottom: 8px;
      }
    }
    .oxide-card {
      & {
        padding: 35px;
      }
      & + hr.wp-block-separator.is-style-wide:not(.thin) {
        margin: 30px 0 25px 0;
        margin-top: 30px;
      }
    }  
    .pagination {
      & {
        margin-top: 0;
        margin-bottom: 10px;
      }
    }    
    article .pill-img {
      & {
        padding: 20px;
      }
      figure {
        width: 40px;
        height: 40px;
      }
      p {
        font-size: 1rem;
      }
    }
    .pill-img.gb-layout-columns-2 > .gb-layout-column-wrap {
      grid-gap: 15px;
      grid-template-columns: 40px 1fr;
      grid-template-areas: "col1 col2";      
    }          
  }
  .ie .gb-layout-column-wrap { //only internet explorer
    flex-direction: column;
  }
  // global rules finish  

  //front-page component
  .banner_image {
    h1 {
      margin-bottom: 1rem; 
    }
    .wp-block-media-text__media {
      &:after {
        left: 36px;
        bottom: -36px;
        top: 36px;
      }
    }
    .wp-block-media-text__content {
      .wp-block-media-text__media {
        margin: 20px 0 46px;
      }
      .wp-block-buttons {
        margin-top: 20px;
      }
    }
    &.contact-page {
      & {
        margin-bottom: 16px;
      }
      ul {
        font-size: 1rem;
        margin-top: -16px;
      } 
    }
  }
  .banner_image.reverse {
    .wp-block-media-text__content p {
      & {
        font-size: 1rem;   
      }        
    }
    
    

    .wp-block-media-text__media {
      & {
        padding: 0 0 0 26px;
      }
      &:after {
        bottom: -26px;
      }
    }
  }
  .wp-block-embed-youtube {
    margin-top: 20px;
  }
  //hr if comes after p.desc
  .entry-content > .wp-block-group:first-child + .wp-block-separator:not(.thin) {
    margin: 25px 0 25px 0
  }
  .snomed-heading {
    margin-bottom: 24px;
  }
  .video_info {
    margin-top: 30px;
  }
  .wp-block-column:not(:only-child) { //youtube video
    flex-basis: 100%!important;
  }
  .wp-block-column:nth-child(2n) {
    margin-left: 0;
  }
  .pill-long {
    & {
      padding: 20px;
    }
    p {
      & {
        font-size: 1rem; 
        margin-left: 60px;
      }
      &:before {
        width: 40px;
        height: 40px;
        left: -60px;
      }
      img {
        margin-left: 15px;
        height: 24px;
        width: auto !important; //overriding wordpress inline style
      }
    }
    &.gb-layout-columns-2>.gb-layout-column-wrap.gb-is-responsive-column {      
      grid-template-columns: 1fr;
      grid-template-areas:
          "col1"
          "col2";
    }
  }
  .long-pill-columns {
    & {
      margin-bottom: 14px;
    }
  }
  .check-list li {
    font-size: 1rem;
  }
  .caption-img {
    & {
      padding-top: 20px;
      row-gap: 20px;
    }
    figcaption {
      padding: 20px;
    }
  }
  .contact-card {
    p {
      margin-bottom: 16px;
    }
    .wp-block-media-text {
      margin-bottom: 0;
    }
  }
  .ref, .ref-multi {
    th:first-child, td:first-child {
      padding-right: 22.5px;
    }
    th:last-child, td:last-child {
      padding-left: 22.5px;
    }
  }

  .ref-multi {    
    &.wp-block-table th, &.wp-block-table td {
      padding-right: 22.5px;
    }
    th:last-child, td:last-child {
      padding-left: 0;
    }  
  }

  .three-card-block .three-card-item:last-child {
    // margin-bottom: 0;
  }
  

  //home page
  .home h2 {
    margin-bottom: 0.5rem;
  }
  //news-and-events page
  .news-col {
    .three-card-item {
      margin-bottom: 0;
      height: auto;
      padding: 32px ;
    }   
  }


  .fy {
    p.desc {
      margin-bottom: 30px;
    }
    p.select-para {
      margin-bottom: 16px;
    }
    .custom-select {
      margin-bottom: 24px;
    }
    p.update {
      margin-top: 0;
    }
    .gb-layout-column-wrap {
      flex-direction: column;
    }    
    .gb-block-layout-column-inner {
      & {
        min-height: 0;
        padding: 26px 20px 36px
      }
      & > p {
        padding-left: 0;
        font-size: 2rem;
        line-height: 1.1;  
      }
      .wp-block-button {
        margin-top: 1px;
      }
    }    
  }
  .news-events {
    h1 {
      margin-bottom: 1rem;
    }
  }


  //faq page sort of global rules
  //details tag component inbuilt
  .gb-block-accordion > details[open],
  .gb-block-accordion > details {
    padding: 20px 20px 20px 20px;
  }
  .gb-block-accordion > details:not([open])::after {
    left: 0;
  }
  .gb-block-accordion .gb-accordion-text {
    padding: 15px 0 0 0;
  }
  .gb-block-accordion .gb-accordion-text p {
    padding-left: 0;
  }
  .gb-block-accordion .gb-accordion-text > * { //tricky, other solutions cause blue border moving issue.
    padding: 0 0 0 0px;
  }
  .gb-block-accordion .gb-accordion-text > ul,
  .gb-block-accordion .gb-accordion-text > ol { 
    padding: 0 0 0 15px;
  }



  //glossary page
  .page-id-2687 {
    p.desc {
      margin-bottom: 0;
    }
    h2 {
      margin-bottom: 12px;
    }
    .wp-block-genesis-blocks-gb-column p {
      margin-bottom: 8px;
    }
    hr.wp-block-separator.thin.is-style-wide {
      margin: 8px 0 16px;
    }
    .gb-layout-columns-2 + hr.wp-block-separator.is-style-wide:not(.thin) {
      margin: 42px 0 25px;
    }
  }

  //single post  
  .type-post {
    & > h2 {
      margin-bottom: 8px;
      line-height: 1.2;
    }
    & > h3 {
      margin-bottom: 8px;
      line-height: 1.2;
    }
    .main-para {
      font-size: 1rem;
      margin-bottom: 0;
      line-height: 1.5;
    }
    .uct_cards {
      margin-top: 17px;
    }
  }
  .post-header { //still single post
    padding: 20px 20px 15px;
    margin-bottom: 20px;
  }
  .post-title {
    margin-bottom: 16px;
  }
  .post-date {
    margin-bottom: 8px;
  }
  .post-author {
    margin-bottom: 0;
  }


  

  //licensing page
  #post-3057 #more-info ~ p:last-of-type {
    margin-bottom: 10px;
  }
  //contact-us page
  .contact-icons {
    & ~ .wp-block-group h2 {
      margin-bottom: 1rem;
    }
    .wp-block-media-text__content p {
      font-size: 1rem;
    }
    .gb-block-layout-column {
      margin-bottom: 10px;
    }
    .wp-block-media-text.is-stacked-on-mobile {
      grid-template-columns: 50px auto !important; //overriding wp inline styles
    }
    .wp-block-media-text.is-stacked-on-mobile .wp-block-media-text__media {
      grid-column: 1 / 2;
    }
    .wp-block-media-text.is-stacked-on-mobile .wp-block-media-text__content {
      grid-column: 2 / 3;
      grid-row: 1 / 2;
      margin-top: 16px;
    }
  }
  
  

}

//bootstrap mq
@media only screen and (max-width: 575.98px) { 

  //global block rules    
  .wp-block-buttons {
    & {
      flex-direction: column;
      align-items: stretch;
    }
    & > .wp-block-button {
      margin-bottom: 0;
    }
  }
  
  .components {
    .wp-block-button__link {
      min-width: auto;
      width: 100%;
    }    
    .custom-select {
      width: 100%;
    }    
    .uct_cards {
      .three-card-item {
        & {
          padding: 30px 20px 50px 20px;
        }
        .wp-block-button {
          bottom: 10px;
        }
      }
    }    
  }
  .video_info {
    padding: 30px;
  }
  .uct_two_block {
    & {
      margin: -30px 0 0 0;
    }
    h2 {
      margin-bottom: 20px;
    }
    .wp-block-separator:not(.thin) {
      margin: 60px 0 25px 0;
    }
    .gb-block-layout-column:nth-child(2) .wp-block-separator:not(.thin) {
      margin: 20px 0 25px 0;
    }
    .gb-block-layout-column:nth-child(1) .wp-block-button {
      margin-bottom: 25px;
    }
    
    .gb-block-layout-column {
      padding-right: 0;
    }
  }
  .oxide-card {
    .wp-block-button__link {
      width: auto;
    }
  }
  .table-third-party {
    .gb-block-layout-column:nth-child(2) th,
    .gb-block-layout-column:nth-child(2) td {
      padding-left: 0;
    }
  }








  .fy {
    .dropdown-menu {
      min-width: 100%;
    }
  }
  //global block rules finishes



  .banner_image {
    .wp-block-media-text__media {
      & {
        padding: 0 26px 0 0;
      }
      &:after {
        left: 26px;
        bottom: -26px;
        top: 26px;        
      }      
    }
    
  }

  .fy {
    .wp-block-button__link {
      width: 80px;
    }
  }

} 






// Component names in wordpress editor  

/*
  - desc  
  - seperator (always choose wide-line in settings)  
  - list  (this is default list component of gutenberg editor)  
  - oxide_image_regular  
  - btn_pm
  - oxide_card_with_btn
  - pill_lg_btn
1. card_oxide
2. nav_list
3. table_oxide  
4. pill-img, pill-long-no-button  
5. caption_image  
 - blue_list_unordered_oxide
 - oxide_border_list
*/


