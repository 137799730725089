$blue: #015ba6;
$blue-accent: #33acde;
$active-blue: #25abe0;
$border-blue: #c6dfef;
$footer-blue: #003864;
$dark-blue: #003864;

$light-blue: #e9f7fc;
$black: #333333;

$gray-hr: #D6D6D6;
$success: #90C547;



